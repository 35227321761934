import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Menu extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    active_menu: PropTypes.number.isRequired,
    handleActiveMenu: PropTypes.func.isRequired,
  }

  static defaultProps = {
    data: [],
    active_menu: 1,
    handleActiveMenu: () => {},
  }

  renderMenu = (data, active_menu) => {
    return data.map(x => {
      return (
        <p
          key={x.key}
          className={active_menu === x.key ? "dashboard-menu-item active": "dashboard-menu-item"}
          onClick={() => this.props.handleActiveMenu(x.key)}
        >
          {x.value}
        </p>
      );
    });
  }

  render() {
    const { active_menu, data } = this.props;
    return (
      <div className="dashboard-menu-container">
        {this.renderMenu(data, active_menu)}
      </div>
    );
  }
}
