import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Drawer} from 'antd';

import Product from './product_suivi';

import SuiviTrackingDetails from './suivi_tracking_details';

import { trackWindowScroll } from 'react-lazy-load-image-component';

class Suivi extends Component {
  static propTypes = {
    suivi: PropTypes.shape({
      livraisons: PropTypes.array.isRequired,
      orders: PropTypes.array.isRequired,
    }),
    scrollPosition: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    addNewUpdate: PropTypes.func.isRequired,
    openDetailsClient: PropTypes.func.isRequired,
  }

  static defaultProps = {
    suivi: {
      livraisons: [],
      orders: [],
    },
    scrollPosition: null,
    addNewUpdate: () => {},
    openDetailsClient: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      detail: null,
    }
  }

  getDate = (date) => {
    const new_date = new Date(date);
    const day = new_date.getDate() > 9 ? new_date.getDate(): `0${new_date.getDate()}`;
    const month = new_date.getMonth() > 8 ? new_date.getMonth() + 1:`0${new_date.getMonth() + 1}`;
    return `${day}/${month}/${new_date.getFullYear()}`;
  }

  getDeliveryDate = (dates) => {
    return dates.forEach(x => {
      if (x.delivery_date !== null) {
        const new_date = new Date(x.delivery_date);
        const day = new_date.getDate() > 10 ? new_date.getDate(): `0${new_date.getDate()}`;
        const month = new_date.getMonth() > 8 ? new_date.getMonth() + 1:`0${new_date.getMonth() + 1}`;
        return `${day}/${month}/${new_date.getFullYear()}`;
      }
    })
  }

  handleDetail = (detail) => {
    this.setState({ detail });
  }

  renderActivite = (orders) => {
    return orders.sort((a,b) => b.id - a.id).map(x => {
      return (
        <div key={x.id} className="collecte-suivi-activite">
          <p className="collecte-suivi-activite-texte" onClick={() => this.props.openDetailsClient(x.id)}>
            <span className="name">
              {`${x.customer.user.first_name} ${x.customer.user.last_name.substring(0,1)}. `}
            </span>
            vous a offert {this.renderItems(x.items)} <span style={{ fontStyle: 'italic' }}>( il y a {this.renderDate(x.order_date)} )</span>
          </p>
        </div>
      );
    });
  }

  renderDate = (date) => {
    const now = new Date();
    const difference_in_time = now.getTime() - new Date(date).getTime();
    const hours = parseInt(difference_in_time / (1000 * 3600));
    const days = parseInt(difference_in_time / (1000 * 3600 * 24));
    if (hours > 24) {
      if (days === 1) {
        return "1 jour";
      }
      return `${days} jours`;
    } else if (hours < 1) {
      return "moins d'une heure";
    } else if (hours === 1) {
      return "1 heure";
    } else {
      return `${hours} heures`
    }
  }

  renderItems = (items) => {
    if (items.length > 1) {
      if (items.length - 1 > 1) {
        return `${items[0].quantity}x ${items[0].article.nom} et ${items.length - 1} autres produits`;
      }
      return `${items[0].quantity}x ${items[0].article.nom} et ${items.length - 1} autre produit`;
    } else if(items.length === 1) {
      return `${items[0].quantity}x ${items[0].article.nom}`
    }
    return null
  }

  renderLivraisons = (livraisons, orders) => {
    const temp_items = orders.map(x => x.items);
    const temp = temp_items.flat();

    return livraisons.sort((a,b) => b.id - a.id).map(x => {
      const tous_les_produits = temp.filter(y => y.livraison === x.id);
      if (x.delivered) {
        return (
          <div key={x.id} className="collecte-suivi-livraisons alternate">
            <div className="collecte-suivi-livraison">
              <p className="collecte-suivi-livraison-text">Livraison #{x.id}</p>
              <p className="collecte-suivi-livraison-text">{this.getDate(x.created_at)}</p>
              <p className="collecte-suivi-livraison-text">Livraison reçue</p>
              <p
                className="collecte-suivi-livraison-text details"
                onClick={() => this.handleDetail(tous_les_produits)}
              >
                détails produits
              </p>
              <p
                className={x.delivered === false ? "collecte-suivi-livraison-text cta-alternate":"collecte-suivi-livraison-text details cta"}
                onClick={x.delivered === false ? null: () => this.props.addNewUpdate()}
              >
                Publier livraison
              </p>
            </div>
          </div>
        );
      }

      return (
        <div key={x.id} className="collecte-suivi-livraisons">
          <div className="collecte-suivi-livraison first-line">
            <p className="collecte-suivi-livraison-text">Livraison #{x.id}</p>
            <p className="collecte-suivi-livraison-text">{this.getDate(x.created_at)}</p>
            <p className="collecte-suivi-livraison-text">En cours d'acheminement</p>
            <p
              className="collecte-suivi-livraison-text details"
              onClick={() => this.handleDetail(tous_les_produits)}
            >
              détails produits
            </p>
            <p
              className={x.delivered === false ? "collecte-suivi-livraison-text cta-alternate":"collecte-suivi-livraison-text details cta"}
              onClick={x.delivered === false ? null: () => this.props.addNewUpdate()}
            >
              Publier livraison
            </p>
          </div>
          {x.bonDeCommandes.map((y, index) => {
            return <SuiviTrackingDetails 
              key={y.id}
              x={x}
              y={y}
              updateLivraison={(id, data) => this.props.updateLivraison(id, data)}
              handleDetail={this.handleDetail}
            />;
          })}
        </div>
      );
    });
  }

  renderProducts = (detail, scrollPosition) => {
    if (detail === null) {
      return null;
    }

    return detail.map((x, index) => {
      return <Product key={`${x.id}-${index}`}
          terminer={true}
          product={x}
          in_suivi={true}
          scrollPosition={scrollPosition}
        />;
    });
  }

  render() {
    const { livraisons, orders } = this.props.suivi;
    const { scrollPosition } = this.props;
    const { detail } = this.state;

    return (
      <div className="collecte-view-suivi-container">
        <h5 className="titre">Dernières activités</h5>
        <div className="collecte-suivi-activite-container" style={{ marginBottom: 30 }}>
          <div className="collecte-suivi-activites">
            {orders.length === 0 ? 'Aucune':this.renderActivite(orders)}
          </div>
        </div>
        <h5 className="titre">Livraisons</h5>
        <div className="collecte-suivi-livraisons-container">
          <div key={0} className="collecte-suivi-livraisons alternate" style={{ backgroundColor: '#2b8bbc', color: '#fff' }}>
            <div className="collecte-suivi-livraison">
              <p className="collecte-suivi-livraison-text">Numéro de livraison</p>
              <p className="collecte-suivi-livraison-text">Date de commande</p>
              <p className="collecte-suivi-livraison-text">Statut de livraison</p>
              <p className="collecte-suivi-livraison-text">Détails produits</p>
              <p className="collecte-suivi-livraison-text">Action</p>
            </div>
          </div>
          {livraisons.length === 0 ? 'Aucune':this.renderLivraisons(livraisons, orders)}
        </div>
        <Drawer
          title={`Produits de cette livraison`}
          placement="right"
          closable={true}
          onClose={() => this.handleDetail(null)}
          visible={detail !== null ? true:false}
          className="suivi-drawer"
          headerStyle={{ textAlign: 'center' }}
        >
          <div className="items-container suivi">
            {this.renderProducts(detail, scrollPosition)}
          </div>
        </Drawer>
      </div>
    );
  }
}

export default trackWindowScroll(Suivi);
