import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ProduitsIcon, CollectesIcon, DonsIcon } from '../elements/icons';

export default class Stats extends Component {
  static propTypes = {
    collectes: PropTypes.arrayOf(
      PropTypes.shape({
        association: PropTypes.shape({
          nom: PropTypes.string.isRequired,
          adresse: PropTypes.string.isRequired,
          latitude: PropTypes.string.isRequired,
          longitude: PropTypes.string.isRequired,
          adresse_de_livraison: PropTypes.string.isRequired,
          photo: PropTypes.string,
          description: PropTypes.string.isRequired,
          telephone: PropTypes.string.isRequired,
          nombre_de_membre: PropTypes.number,
          site_web: PropTypes.string,
          grand_label_associatif: PropTypes.string.isRequired,
          logo: PropTypes.string,
          defiscalisable: PropTypes.bool.isRequired,
          defiscalisation_percent: PropTypes.number.isRequired,
        }),
        date_debut: PropTypes.string.isRequired,
        date_fin: PropTypes.string,
        description: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        lieu_de_livraison: PropTypes.string.isRequired,
        nom: PropTypes.string.isRequired,
        percent: PropTypes.shape({
          price: PropTypes.number.isRequired,
          quantity_available: PropTypes.number.isRequired,
          total: PropTypes.number.isRequired,
        }),
        photo: PropTypes.string,
        promue: PropTypes.bool.isRequired,
        sous_theme: PropTypes.number.isRequired,
        terminer: PropTypes.bool.isRequired,
        theme: PropTypes.shape({
          name: PropTypes.string.isRequired,
          couleur: PropTypes.string.isRequired,
          image: PropTypes.string.isRequired,
          image_selected: PropTypes.string.isRequired,
        })
      }),
    ),
  }

  static defaultProps = {
    collectes: [],
  }

  getProducts = (data) => {
    const products = data.reduce((acc, val) => {
      acc += val.percent.total - val.percent.quantity_available;
      return acc;
    }, 0);
    return products;
  }

  getPrice = (data) => {
    const price = data.reduce((acc, val) => {
      acc += val.percent.price;
      return acc;
    }, 0.00);
    return price.toFixed(2);
  }


  render() {
    const { collectes } = this.props;

    return (
      <div className="stats-box">
        <div className="stats-item">
          <CollectesIcon />
          <p className="chiffre">
            {collectes.length}
          </p>
          <p className="text">
            {collectes.length > 1 ? "collectes":"collecte"}
          </p>
        </div>
        <div className="stats-item">
          <ProduitsIcon />
          <p className="chiffre">
            {this.getProducts(collectes)}
          </p>
          <p className="text">
            {this.getProducts(collectes) > 1 ? "produits collectés":"produit collecté"}
          </p>
        </div>
        <div className="stats-item">
          <DonsIcon />
          <p className="chiffre">
            {Math.ceil(this.getPrice(collectes))}€
          </p>
          <p className="text">
            {Math.ceil(this.getPrice(collectes)) > 1 ? "de dons reçus":"de don reçu"}
          </p>
        </div>
      </div>
    );
  }
}
