import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// Unauth Routes
import LoginContainer from "../containers/auth/authContainer";
import ActivationContainer from "../containers/auth_activation/authActivationContainer";
import LogoutContainer from "../containers/auth/logoutContainer";
import Signupcontainer from "../containers/auth/signupAssociationContainer";
import ResetPasswordContainer from "../containers/reset_password/resetPasswordContainer";
import MaintenancePage from "../components/maintenance";
import CheckAuth from "../components/check_auth";

// Auth Routes
import Layout from "../containers/layout/layoutContainer";

const getRoutes = (maintenance) => {
  return maintenance ? (
    <Switch>
      <Route
        exact
        path="/association/maintenance"
        render={() => <MaintenancePage />}
      />
      <Route render={() => <Redirect to="/association/maintenance" />} />
    </Switch>
  ) : (
    <Switch>
      <Route exact path="/association" render={() => <CheckAuth />} />
      <Route exact path="/" render={() => <CheckAuth />} />
      <Route
        exact
        path="/association/maintenance"
        render={() => <Redirect to="/association/login" />}
      />
      <Route path="/association/login" exact component={LoginContainer} />
      <Route
        exact
        path="/association/activation/:id"
        component={ActivationContainer}
      />
      <Route path="/association/signout" exact component={LogoutContainer} />
      <Route path="/association/signup" exact component={Signupcontainer} />
      <Route
        exact
        path="/association/reset-password"
        component={ResetPasswordContainer}
      />
      <Layout />
    </Switch>
  );
};

export default getRoutes;
