import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import UpdateCard from '../elements/updateCard';

import { trackWindowScroll } from 'react-lazy-load-image-component';

const { confirm } = Modal;

class Actualites extends Component {
  static propTypes = {
    actualites: PropTypes.array.isRequired,
    addNewUpdate: PropTypes.func.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    deleteActualite: PropTypes.func.isRequired,
    patchActualite: PropTypes.func.isRequired,
  }

  handleUpdate = (id) => {
    this.props.patchActualite(id);
  }

  handleDelete = (id) => {
    const { deleteActualite } = this.props;
    confirm({
      title: 'Êtes vous sûr de vouloir enlever cette actualite ?',
      icon: <ExclamationCircleOutlined />,
      content: "Cette action est irréversible et l'actualité ne sera plus visible par nos donateurs",
      onOk() {
        deleteActualite(id);
      },
      onCancel() {
        return;
      },
    });
  }

  renderActualites = (actualites, updating) => {
    return actualites.sort((a,b) => b.id - a.id).map(x => {
      return <UpdateCard
      key={x.id}
      data={x}
      color={x.color}
      updating={updating}
      handleDelete={(id) => this.handleDelete(id)}
      handleUpdate={(id) => this.handleUpdate(id)}
      />
    });
  }

  render() {
    const { actualites, updating } = this.props;

    return (
      <div className="collecte-view-actualite-container">
        <p
          className="collecte-view-actualite-cta"
          onClick={() => this.props.addNewUpdate()}
        >
          <i className="fal fa-plus"></i> Publier un message
        </p>
        <div style={{ marginTop: 20 }}>
        {actualites.length === 0 ?
          'Aucune':
          this.renderActualites(actualites, updating)
        }
        </div>
      </div>
    )
  }
}

export default trackWindowScroll(Actualites);
