import React from "react";

import Logo from "../../resources/img/logo_white.svg";
import MAINTENANCE_IMAGE from "../../resources/img/pet_on_screen.svg";

import env from "../../config/env";

const { ASSOCIATION_URL } = env;

const Maintenance = () => {
  return (
    <div style={{ background: "rgb(232, 222, 222)" }}>
      <div>
        <img src={Logo} style={{ width: "10%", margin: "2%" }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div>
          <h1 style={{ fontWeight: 300 }}>Wisdana est en maintenance.</h1>
          <h2 style={{ fontWeight: 200 }}>On revient bientôt !</h2>
          <img
            src={MAINTENANCE_IMAGE}
            style={{ width: "80%", marginTop: 20 }}
          />
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
