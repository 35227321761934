import React, { Component } from "react";
import PropTypes from "prop-types";

import { Input, Select, Tooltip } from "antd";

import { QuestionCircleOutlined } from "@ant-design/icons";

import ImageUploader from "../elements/imageUploader";

const { TextArea } = Input;

const { Option } = Select;

export default class Informations extends Component {
  static propTypes = {
    collecte: PropTypes.shape({
      nom: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      lieu_de_livraison: PropTypes.string.isRequired,
      date_debut: PropTypes.string,
      date_fin: PropTypes.string,
      terminer: PropTypes.bool.isRequired,
      validated: PropTypes.bool.isRequired,
      association: PropTypes.object.isRequired,
      theme: PropTypes.number,
      sous_theme: PropTypes.number,
      informations_de_livraison: PropTypes.string,
    }),
  };

  static defaultProps = {
    collecte: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      nom: null,
      photo: null,
      description: null,
      adresse: null,
      code_postal: null,
      ville: null,
      pays: "FR",
      informations_de_livraison: null,
    };
  }

  componentDidMount() {
    const { collecte } = this.props;
    const adresse = collecte.lieu_de_livraison.split(", ");
    this.setState({
      nom: collecte.nom,
      photo: collecte.photo,
      description: collecte.description,
      adresse: adresse[0],
      code_postal: adresse[1],
      ville: adresse[2],
      pays: adresse[3],
      informations_de_livraison: collecte.informations_de_livraison,
      telephone_de_livraison: collecte.telephone_de_livraison,
    });
  }

  changeNom = (nom) => {
    this.setState({ nom });
  };

  changeDescription = (description) => {
    this.setState({ description });
  };

  changePhoto = (photo) => {
    this.setState({ photo });
  };

  changeAdresse = (adresse) => {
    this.setState({ adresse });
  };

  changeCodePostal = (code_postal) => {
    this.setState({ code_postal });
  };

  changeVille = (ville) => {
    this.setState({ ville });
  };

  changePays = (pays) => {
    this.setState({ pays });
  };

  changeInformationsDeLivraison = (informations_de_livraison) => {
    this.setState({ informations_de_livraison });
  };

  changeTelephoneDeLivraison = (telephone_de_livraison) => {
    this.setState({ telephone_de_livraison });
  };

  renderCountries = (country) => {
    const countries = [
      {
        value: "UK",
        name: "Angleterre",
      },
      {
        value: "AT",
        name: "Autriche",
      },
      {
        value: "BE",
        name: "Belgique",
      },
      {
        value: "US",
        name: "États Unis",
      },
      {
        value: "CA",
        name: "Canada",
      },
      {
        value: "CN",
        name: "Chine",
      },
      {
        value: "FR",
        name: "France",
      },
      {
        value: "DE",
        name: "Allemagne",
      },
      {
        value: "IT",
        name: "Italie",
      },
      {
        value: "NL",
        name: "Hollande",
      },
      {
        value: "PT",
        name: "Portugal",
      },
      {
        value: "ES",
        name: "Espagne",
      },
      {
        value: "CH",
        name: "Suisse",
      },
    ];
    const france = [
      {
        value: "FR",
        name: "France",
      },
    ];
    return (
      <Select
        onChange={(event) => this.changePays(event)}
        value={country}
        bordered={false}
        style={{ width: "100%" }}
      >
        {france.map((x, index) => {
          return (
            <Option key={index} value={x.value}>
              {x.name}
            </Option>
          );
        })}
      </Select>
    );
  };

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (!this.props.collecte.terminer) {
      this.props.updateCollecte({ ...this.state });
    }
  };

  render() {
    const { collecte } = this.props;
    const {
      nom,
      photo,
      description,
      adresse,
      code_postal,
      ville,
      pays,
      informations_de_livraison,
      telephone_de_livraison,
    } = this.state;

    return (
      <div className="collecte-view-informations-container">
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="div-input long">
            <label>
              Titre de la collecte{" "}
              <Tooltip title="Choisissez bien, ce titre sera vu par tous vos donateurs !">
                <QuestionCircleOutlined />
              </Tooltip>
            </label>
            <Input
              placeholder=""
              name="nom"
              value={nom}
              onChange={(e) => this.changeNom(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Description de la collecte</label>
            <TextArea
              rows={4}
              placeholder=""
              name="description"
              value={description}
              onChange={(e) => this.changeDescription(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Photo de couverture</label>
            <ImageUploader
              changePhoto={(photo) => this.changePhoto(photo)}
              image={photo}
            />
          </div>
          <p className="titre">Adresse de livraison</p>
          <div className="div-input long">
            <label>Adresse</label>
            <Input
              placeholder=""
              name="adresse"
              value={adresse}
              onChange={(e) => this.changeAdresse(e.target.value)}
            />
          </div>
          <div className="multiple-input">
            <div className="div-input">
              <label>Code Postal</label>
              <Input
                placeholder=""
                name="code_postal"
                value={code_postal}
                onChange={(e) => this.changeCodePostal(e.target.value)}
              />
            </div>
            <div className="div-input">
              <label>Ville</label>
              <Input
                placeholder=""
                name="ville"
                value={ville}
                onChange={(e) => this.changeVille(e.target.value)}
              />
            </div>
          </div>
          <div className="div-input long">
            <label>Pays</label>
            <div
              className="ant-input"
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FBFAFA",
                borderRadius: 5,
                height: 36,
              }}
            >
              <div
                id="country"
                className={`${pays.toLowerCase()}`}
                style={{ height: 16 }}
              ></div>
              {this.renderCountries(pays)}
            </div>
          </div>
          <div className="div-input long">
            <label>Informations de livraison</label>
            <Input
              placeholder=""
              name="informations_de_livraison"
              value={informations_de_livraison}
              onChange={(e) =>
                this.changeInformationsDeLivraison(e.target.value)
              }
            />
          </div>
          <div className="div-input long">
            <label>Numero de telephone pour la livraison</label>
            <Input
              placeholder=""
              name="telephone_de_livraison"
              value={telephone_de_livraison}
              onChange={(e) => this.changeTelephoneDeLivraison(e.target.value)}
            />
          </div>
          <div className="submit">
            {!collecte.terminer && (
              <button
                type="submit"
                disabled={
                  !nom ||
                  !description ||
                  !photo ||
                  !adresse ||
                  !code_postal ||
                  !ville ||
                  !pays
                }
              >
                Enregistrer
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}
