import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { authUser, signup, refresh } from "./actions";

import { parse } from "query-string";

import { Link } from "react-router-dom";

import logo from "../../resources/img/logo_white.svg";
import HeaderBG from "../../resources/img/header_bg.png";

import Login from "../../components/auth/login";
import Signup from "../../components/auth/signup";

// import custom css
import "./auth.scss";
import "../../components/auth/style.scss";
import "../../components/elements/style.scss";

import env from "../../config/env";
const { APP_URL } = env;

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signup: false,
      onLoginRedirectUrl: "/association/dashboard",
      name: null,
    };
  }

  static propTypes = {
    authenticated: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    activation: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    authenticated: null,
    error: null,
    activation: false,
  };

  componentDidMount() {
    const query = parse(window.location.search);
    if (query.url) {
      this.setState({ onLoginRedirectUrl: query.url });
    }
    if (query.name) {
      this.setState({ signup: true, name: query.name });
    }
  }

  authUser = (data) => {
    this.props.authUser(data);
  };

  signup = (data) => {
    this.props.signup(data);
  };

  switchSignup = () => {
    this.props.refresh();
    this.setState({ signup: !this.state.signup });
  };

  render() {
    const { authenticated, activation, error } = this.props;
    const { onLoginRedirectUrl, name } = this.state;

    if (authenticated) {
      return <Redirect push={true} to={onLoginRedirectUrl} />;
    }

    return (
      <div
        className="loginContainer"
        style={{ backgroundImage: `url(${HeaderBG})` }}
      >
        <div className="loginContainerBox">
          <img src={logo} className="logo" alt="logo" />
          <p className="association-question">
            Vous êtes un particulier ?{" "}
            <a href={`${APP_URL}/app/feed`}>Cliquez ici</a>
          </p>
          <div className="loginSignupBox">
            {!this.state.signup ? (
              <Login authUser={(data) => this.authUser(data)} error={error} />
            ) : (
              <Signup
                signup={(data) => this.signup(data)}
                error={error}
                name={name}
                activation={activation}
              />
            )}
            <p className="retour-au-site">
              <Link to="/association/reset-password">
                Mot de passe oublié ?
              </Link>
            </p>
          </div>
          <p
            style={{ cursor: "pointer", marginTop: 20 }}
            onClick={() => this.switchSignup()}
          >
            {this.state.signup ? "Se connecter" : "Créer un compte"}
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    error: state.auth.error,
    activation: state.auth.activation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      authUser,
      signup,
      refresh,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
