import React from 'react';

const Tracker = ({ color, percent, height, radius }) => {
  return (
    <div className="tracker-container" style={{ height: height }}>
      <div className="tracker" style={{ borderRadius: radius }}></div>
      <div
        className="tracker-value"
        style={{
          borderTopLeftRadius: radius,
          borderBottomLeftRadius: radius,
          backgroundColor: color,
          width: `${parseInt(percent)}%`,
          borderTopRightRadius: parseInt(percent) === 100 ? radius:0,
          borderBottomRightRadius: parseInt(percent) === 100 ? radius: 0,
        }}></div>
    </div>
  );
}

export default Tracker;
