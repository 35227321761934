import React, { Component } from 'react';

import './commentCaMarche.scss'

export default class TutoCreerUneCollecte extends Component {
  render() {
    return (
      <div className="help-container">
        <video autoPlay controls style={{ outline: 'none' }} poster="https://wisdana.ams3.cdn.digitaloceanspaces.com/static/landing/wisdana_blue_preview.png">
          <source src="https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/creation_collecte.mp4" type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
    );
  }
}
