import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RecuFiscaux extends Component {
  static propTypes = {
    orders: PropTypes.array.isRequired,
  }

  static defaultProps = {
    orders: [],
  }

  getCost = (items) => {
    const cost = items.reduce((acc, val) => {
      acc += parseFloat(val.price);
      return acc;
    }, 0.00);
    return cost.toFixed(2);
  }

  getDate = (date) => {
    const new_date = new Date(date);
    return `${new_date.getDate()}/${new_date.getMonth() + 1}/${new_date.getFullYear()}`;
  }

  renderActivite = (orders) => {
    return orders.sort((a,b) => b.id - a.id).map(x => {
      return (
        <div key={x.id} className="collecte-suivi-activite">
          <p className="collecte-suivi-activite-display">
            Reçu fiscal pour {x.customer.user.first_name} {x.customer.user.last_name}, donateur #{x.customer.id} le {this.getDate(x.order_date)} pour {this.getCost(x.items)}  <a href={x.recu_fiscaux.link} target="_blank" rel="noopener noreferrer">voir reçu</a>
          </p>
        </div>
      );
    });
  }

  render() {
    const { orders } = this.props;

    return (
      <div className="collecte-view-suivi-container">
        <h5 className="titre">Reçus fiscaux</h5>
        <div className="collecte-suivi-activite-container" style={{ marginBottom: 30 }}>
          <div className="collecte-suivi-activites">
            {orders.length === 0 ? 'Aucun':this.renderActivite(orders)}
          </div>
        </div>
      </div>
    );
  }
}
