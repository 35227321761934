const envs = {
  production: {
    API_HOST: "https://api.wisdana.com/api",
    APP_URL: "https://app.wisdana.com",
    CONTACT_EMAIL: "alexandre@wisdana.com",
    PUBLIC_URL: "https://association.wisdana.com",
    ROOT_DOMAIN: "wisdana.com",
    TEAM_URL: "https://equipe.wisdana.com",
  },

  development: {
    // API_HOST: "http://localhost:8000/api",
    API_HOST: "https://api.sharingsharing.com/api",
    APP_URL: "https://app.sharingsharing.com",
    CONTACT_EMAIL: "alexandre@sharingsharing.com",
    PUBLIC_URL: "https://association.sharingsharing.com",
    ROOT_DOMAIN: "sharingsharing.com",
    TEAM_URL: "https://equipe.sharingsharing.com",
  },
};

export default envs[process.env.REACT_APP_ENV || "development"];
