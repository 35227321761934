export const AUTH_ERROR = 'containers/layout/auth_error';
export const AUTH_VERIFICATION = 'containers/layout/auth_verification';

export const AUTH_USER = 'containers/auth/auth_user';
export const UNAUTH_USER = 'containers/auth/unauth_user';

export const AUTH_ACTIVATION = 'containers/auth_activation/auth_activation';

export const FETCH_USER = 'containers/layout/fetch_user';
export const GET_ASSOCIATION = 'containers/informations/get_association';
