import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.scss';

import ImageUploaderNoInput from '../elements/imageUploaderNoInput';

export default class Subheader extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.shape({
      user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }),
      association: PropTypes.shape({
        nom: PropTypes.string.isRequired,
        adresse: PropTypes.string,
        latitude: PropTypes.string,
        longitude: PropTypes.string,
        photo: PropTypes.string,
        description: PropTypes.string,
        telephone: PropTypes.string,
        nombre_de_membre: PropTypes.number,
        site_web: PropTypes.string,
        grand_label_associatif: PropTypes.string,
        logo: PropTypes.string,
        defiscalisable: PropTypes.bool.isRequired,
        defiscalisation_percent: PropTypes.number.isRequired,
      }),
    }).isRequired,
    uploadLogo: PropTypes.func.isRequired,
  }

  state = {
    sub_menu: false,
  }

  showSubMenu = () => {
    this.setState({ sub_menu: !this.state.sub_menu });
  }

  renderMenu = (location) => {
    const array = [
    {
      key: "/association/nouvelle-collecte",
      value: "Nouvelle collecte",
      icon: 'fa-plus-circle',
    },
    {
      key: "/association/dashboard",
      value: "Mes collectes",
      icon: 'fa-tasks',
    },{
      key: "/association/informations",
      value: "Mes informations",
      icon: 'fa-cogs',
    },{
      key: "/association/produits",
      value: "Nos produits",
      icon: 'fa-gifts',
    },{
      key: "/association/help",
      value: "Aide",
      icon: 'fa-question-circle',
    }];

    const test_location = location.split('/').slice(0,3).join('/');


    // Testing for submenu
    // return array.map(x => {
    //   if (x.key === '/association/help' && this.state.sub_menu === true) {
    //     return [
    //       <div
    //         key={x.key}
    //         className={test_location.includes(x.key) ? "dashboard-panel-item active":"dashboard-panel-item"}
    //         onClick={() => this.showSubMenu()}
    //       >
    //         <div className="dashboard-panel-item-icon-text">
    //           <i className={`fad ${x.icon}`} style={{ color: '#2b8bbc' }}></i>
    //           <p className="dashboard-panel-item-text">{x.value}</p>
    //         </div>
    //       </div>,
    //       <Link
    //         key={'/association/help/informations'}
    //         className={test_location.includes('/association/help/informations') ? "dashboard-panel-item alternate active":"dashboard-panel-item alternate"}
    //         to={'/association/help/informations'}
    //       >
    //         <div className="dashboard-panel-item-icon-text">
    //           <i className={`fad ${x.icon}`} style={{ color: '#2b8bbc' }}></i>
    //           <p className="dashboard-panel-item-text">Informations</p>
    //         </div>
    //       </Link>,
    //       <Link
    //         key={'/association/help/collecte-reussi'}
    //         className={test_location.includes('/association/help/collecte-reussi') ? "dashboard-panel-item alternate active":"dashboard-panel-item alternate"}
    //         to={'/association/help/collecte-reussi'}
    //       >
    //         <div className="dashboard-panel-item-icon-text">
    //           <i className={`fad ${x.icon}`} style={{ color: '#2b8bbc' }}></i>
    //           <p className="dashboard-panel-item-text">Une Collecte Réussie</p>
    //         </div>
    //       </Link>
    //     ];
    //   } else if (x.key === '/association/help') {
    //     return (
    //       <div
    //         key={x.key}
    //         className={test_location.includes(x.key) ? "dashboard-panel-item active":"dashboard-panel-item"}
    //         onClick={() => this.showSubMenu()}
    //       >
    //         <div className="dashboard-panel-item-icon-text">
    //           <i className={`fad ${x.icon}`} style={{ color: '#2b8bbc' }}></i>
    //           <p className="dashboard-panel-item-text">{x.value}</p>
    //         </div>
    //       </div>
    //     );
    //   }
    return array.map(x => {
      return (
        <Link
          key={x.key}
          className={test_location.includes(x.key) ? "dashboard-panel-item active":"dashboard-panel-item"}
          to={x.key}
        >
          <div className="dashboard-panel-item-icon-text">
            <i className={`fad ${x.icon}`} style={{ color: '#2b8bbc' }}></i>
            <p className="dashboard-panel-item-text">{x.value}</p>
          </div>
        </Link>
      );
    });
  }

  renderLogo = (user) => {
    return (
      <div className="logo">
        <div style={{
          height: 100,
          width: 100,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          fontWeight: 600
        }}>
          <ImageUploaderNoInput
            changePhoto={(logo) => this.props.uploadLogo({ logo: logo })}
            image={user.association.logo}
          />
        </div>
      </div>
    );
  }

  render() {
    const { children, user, location } = this.props;

    return (
      <div className="dashboard-header">
        <div className="dashboard-panel">
          {this.renderLogo(user)}
          <h2 className="dashboard-panel-title">{user.association.nom}</h2>
          <div className="dashboard-panel-menu">
            {this.renderMenu(location.pathname)}
            <Link
              key={0}
              className="dashboard-panel-item"
              to={'/association/signout'}
            >
              <div className="dashboard-panel-item-icon-text">
                <i className="fad fa-sign-out" style={{ color: '#2b8bbc' }}></i>
                <p className="dashboard-panel-item-text">Se déconnecter</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="component-layout">
          {children}
        </div>
      </div>
    );
  }
}
