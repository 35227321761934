import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { confirmActivation } from './actions';

import Skeleton from '../../components/skeleton';

import { parse } from 'query-string';

class AuthActivationContainer extends Component {
  static propTypes = {
    status: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    status: false,
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      redirect: '/association/help',
    }
  }

  componentDidMount() {
		const query = parse(window.location.search);
    this.setState({ redirect: query.redirect });

		this.props.confirmActivation({
			userId: this.props.match.params.id,
			token: query.token
		});
	}

  render() {
    const { status } = this.props;
    const { redirect } = this.state;

    if (!status) {
      return <Skeleton />;
    } else {
      return <Redirect push={true} to={`${redirect}`} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    status: state.auth_activation.status,
    error: state.auth_activation.error,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    confirmActivation,
  },dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(AuthActivationContainer);
