import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Product from './product';
import Produits from '../nouvelle_collecte/products';

import { Drawer } from 'antd';

import { trackWindowScroll } from 'react-lazy-load-image-component';

class Products extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    addProduit: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    getProductsByName: PropTypes.func.isRequired,
    updateProduit: PropTypes.func.isRequired,
    deleteProduit: PropTypes.func.isRequired,
    terminer: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    suivi: PropTypes.object.isRequired,
    produits_list: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    categories: PropTypes.array.isRequired,
  }

  static defaultProps = {
    products: [],
    scrollPosition: null,
    addProduit: () => {},
    getProducts: () => {},
    getProductsByName: () => {},
    updateProduit: () => {},
    deleteProduit: () => {},
    terminer: false,
    suivi: {},
    categories: [],
    produits_list: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      drawer: false,
      items: [],
    }
  }

  handleAddNewItem = (new_item) => {
    const items = [...this.state.items, new_item];
    this.setState({ items });
  }

  handleUpdateItem = (new_item, quantite) => {
    const items = this.state.items.reduce((acc, val) => {
      if (new_item.id === val.id) {
        if (parseInt(quantite) > 0) {
          new_item.quantity = parseInt(quantite);
          acc.push(new_item);
        } else if (quantite === "") {
          new_item.quantity = quantite;
          acc.push(new_item);
        }
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
    this.setState({ items });
  }

  handleAddProduit = (items) => {
    this.setState({ items: [], drawer: false });
    this.props.addProduit({ items: items });
  }

  renderProducts = (products, suivi, scrollPosition, terminer, color) => {
    return products.map(x => {
      return <Product
          key={x.id}
          terminer={terminer}
          product={x}
          suivi={suivi}
          in_suivi={false}
          color={color}
          scrollPosition={scrollPosition}
          updateProduit={(id, data) => this.props.updateProduit(id, data)}
          deleteProduit={(id) => this.props.deleteProduit(id)}
        />;
    });
  }

  toggleDrawer = (drawer) => {
    this.props.getProducts(0);
    this.setState({ drawer })
  }

  render() {
    const { products, scrollPosition, terminer, color, suivi, produits_list, categories } = this.props;
    const { drawer, items } = this.state;

    return (
      <div className="items-container">
        <div key={0} className="item" style={{ alignSelf: 'center', border: 'none' }}>
          <p className="new-product-icon fa-4x" onClick={() => this.toggleDrawer(true)}>
            <i className="fad fa-plus-circle"></i>
          </p>
          <p className="new-product-texte">Ajouter un<br />nouveau produit</p>
        </div>
        {products.length === 0 ? 'Aucun produit':this.renderProducts(products, suivi, scrollPosition, terminer, color)}
        <Drawer
          title="Sélectionner des produits"
          placement="right"
          closable={true}
          onClose={() => this.toggleDrawer(false)}
          visible={drawer}
          className="cart-drawer"
          headerStyle={{ textAlign: 'center' }}
          destroyOnClose={true}
          footerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}
          footer={
            (
              <div className="cta-validate-selection" onClick={() => this.handleAddProduit(items)} style={{ fontSize: 18, lineHeight: '18px' }}>
                Valider ma sélection
              </div>
            )
          }
        >
          <Produits
            products={produits_list}
            categories={categories}
            items={products.map(x => x.article)}
            handleAddNewItem={(data) => this.handleAddNewItem(data)}
            handleRemoveItem={(data) => this.handleUpdateItem(data)}
            getProducts={(data) => this.props.getProducts(data)}
            getProductsByName={(data) => this.props.getProductsByName(data)}
            scrollPosition={scrollPosition}
          />
        </Drawer>
      </div>
    );
  }
}

export default trackWindowScroll(Products);
