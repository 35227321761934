import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import ImageUploader from '../elements/imageUploader';

const { TextArea } = Input;

export default class NouvelleCollecteStep2 extends Component {
  static propTypes = {
    themes: PropTypes.array.isRequired,
    handleNext: PropTypes.func.isRequired,
  }

  static defaultProps = {
    themes: [],
    handleNext: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      theme: 0,
      description: null,
      photo: null,
      visible: null,
      sending: false,
    }
  }

  componentDidMount() {
    document.getElementsByClassName("component-layout")[0].scrollTo(0, 0);
    window.scrollTo(0,0);
  }

  componentWillUnmount() {
    this.setState({ sending: false });
  }

  changeTheme = (theme) => {
    if (this.state.theme === theme) {
      this.setState({ theme: 0 });
    } else {
      this.setState({ theme });
    }
  }

  changeVisible = (visible) => {
    this.setState({ visible });
  }

  changeDescription = (description) => {
    this.setState({ description });
  }

  changePhoto = (photo) => {
    this.setState({ photo });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.setState({ sending: true });
    this.props.handleNext({ ...this.state });
  }

  renderCategories = (themes) => {
    return themes.map(x => {

      if (this.state.theme === x.id) {
        return (
          <div
            key={x.id}
            className="theme"
            onClick={() => this.changeTheme(x.id)}
          >
            <img src={x.image_selected} alt={x.name} />
          </div>
        );
      }

      if (this.state.visible === x.id) {
        return (
          <div
            key={x.id}
            className="theme"
            onClick={() => this.changeTheme(x.id)}
            onMouseOut={() => this.changeVisible(null)}
          >
            <img src={x.image_selected} alt={x.name} />
          </div>
        );
      }

      return (
        <div
          key={x.id}
          className="theme"
          onClick={() => this.changeTheme(x.id)}
          onMouseOver={() => this.changeVisible(x.id)}
          onMouseOut={() => this.changeVisible(null)}
        >
          <img src={x.image} alt={x.name} />
        </div>
      );
    });
  }

  render() {
    const { themes } = this.props;
    const { theme, description, photo, sending } = this.state;

    return (
      <div className="nouvelle-collecte-step-container">
        <p className="titre">
          Quelques informations importantes pour vos futurs donateurs
        </p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="div-input long">
            <label>Catégorie <sup>*</sup></label>
            <div className="themes">
              {this.renderCategories(themes)}
            </div>
            {theme !== 0 && <p className="category-title" style={{ backgroundColor: `${themes.find(x => x.id === theme).couleur}` }}>{themes.find(x => x.id === theme).name}</p>}
          </div>
          <div className="div-input long">
            <label>Description de la collecte <sup>*</sup></label>
            <TextArea
              rows={4}
              placeholder=""
              name="description"
              value={description}
              onChange={(e) => this.changeDescription(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Photo de couverture (<i>format paysage</i>) <sup>*</sup></label>
            <ImageUploader
              changePhoto={(photo) => this.changePhoto(photo)}
            />
          </div>
          <div className="div-input long">
            <p>* <i>obligatoire</i></p>
          </div>
          <div className="submit">
            {sending && <button type="submit" disabled={true}>
              <LoadingOutlined />
            </button>}
            {!sending && <button type="submit" disabled={theme === 0 || !description || !photo}>
              Suivant <i className="fad fa-chevron-double-right"></i>
            </button>}
          </div>
          <p className="lien-revenir" onClick={() => this.props.handleBack()}>
            Revenir à l'étape précédente
          </p>
        </form>
      </div>
    );
  }
}
