import axios from 'axios';

import {
  CREATE_COLLECTE,
  GET_THEMES,
  GET_PRODUCTS,
  GET_CATEGORIES,
  UPDATE_COLLECTE,
  SUGGESTION,
  GET_ASSOCIATION,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const COLLECTE_URL = API_ROUTES.collecte;
const THEMES_URL = API_ROUTES.themes;
const CATEGORIES_URL = API_ROUTES.categories;
const PRODUCTS_URL = API_ROUTES.products;
const ASSOCIATION_URL = API_ROUTES.association;
const ARTICLE_URL = API_ROUTES.article;

export function getAssociation() {
  return function(dispatch) {
    axios.get(`${ASSOCIATION_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_ASSOCIATION, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function createCollecte(data) {
  return function(dispatch) {
    data.date_debut = new Date(data.date_debut).toISOString();
    const temp_date = new Date(data.date_fin);
    data.date_fin = new Date(temp_date.setDate(temp_date.getDate() + 1)).toISOString();
    data.description = data.description.trim();

    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    axios.post(`${COLLECTE_URL}`, formData, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: CREATE_COLLECTE, payload: response.data });
    })
    .catch(error => {
      console.log(error.response.data.error);
    });
  }
}

export function getThemes() {
  return function(dispatch) {
    axios.get(`${THEMES_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_THEMES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response.data.error);
    });
  }
}

export function getProductsByName(name) {
  return function (dispatch) {
    dispatch({ type: GET_PRODUCTS, payload: [] });
    axios.get(`${PRODUCTS_URL}?name=${name}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUCTS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function getProducts(category_id) {
  return function(dispatch) {
    dispatch({ type: GET_PRODUCTS, payload: [] });
    axios.get(`${CATEGORIES_URL}${category_id}/products/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUCTS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function getCategories() {
  return function(dispatch) {
    axios.get(`${CATEGORIES_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_CATEGORIES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function updateCollecte(data, id) {
  return function(dispatch) {
    axios.post(`${COLLECTE_URL}${id}/article/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: UPDATE_COLLECTE, payload: true });
    })
    .catch(error => {
      console.log(error.response.data.error);
    });
  }
}

export function sendSuggestion(data) {
  return function(dispatch) {
    axios.post(`${ARTICLE_URL}suggestion/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: SUGGESTION, payload: true });
    })
    .catch(error => {
      console.log(error.response.data.error);
    });
  }
}

export function toggleSuggestion() {
  return function (dispatch) {
    dispatch({ type: SUGGESTION, payload: null });
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: CREATE_COLLECTE, payload: null });
    dispatch({ type: GET_THEMES, payload: null });
    dispatch({ type: GET_PRODUCTS, payload: null });
    dispatch({ type: UPDATE_COLLECTE, payload: false });
    dispatch({ type: SUGGESTION, payload: null });
    dispatch({ type: GET_ASSOCIATION, payload: null });
  }
}
