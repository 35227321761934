import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, Select, Tooltip } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const minDate = () => {
  var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;

  return [year, month, day].join('-');
}

const maxDate = () => {
  var d = new Date(),
      month = '' + (d.getMonth() + 2),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) {
      month = '0' + month;
  } else if (month === '13') {
    month = '01';
    year = year + 1;
  }
  if (day.length < 2)
      day = '0' + day;

  return [year, month, day].join('-');
}

export default class NouvelleCollecteStep1 extends Component {
  static propTypes = {
    handleNext: PropTypes.func.isRequired,
  }

  static defaultProps ={
    handleNext: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      nom: null,
      date_debut: minDate(),
      date_fin: maxDate(),
      adresse: null,
      code_postal: null,
      ville: null,
      pays: 'FR',
      informations_de_livraison: null,
      telephone_de_livraison: null,
    }
  }

  componentDidMount() {
    const { data, association } = this.props;
    if (data !== null) {
      this.setState({
        nom: data.nom,
        date_debut: data.date_debut,
        date_fin: data.date_fin,
        adresse: data.adresse,
        code_postal: data.code_postal,
        ville: data.ville,
        pays: data.pays,
        informations_de_livraison: data.informations_de_livraison,
        telephone_de_livraison: data.telephone_de_livraison,
      });
    }

    if (association !== null && data === null) {
      this.setState({ nom: `Collecte pour ${association.nom}`});
    }
  }

  changeNom = (nom) => {
    this.setState({ nom });
  }

  changeDateDebut = (date_debut) => {
    this.setState({ date_debut, date_fin: null });
  }

  changeDateFin = (date_fin) => {
    this.setState({ date_fin });
  }

  changeAdresse = (adresse) => {
    this.setState({ adresse });
  }

  changeCodePostal = (code_postal) => {
    this.setState({ code_postal });
  }

  changeVille = (ville) => {
    this.setState({ ville });
  }

  changePays = (pays) => {
    this.setState({ pays });
  }

  changeInformationsDeLivraison = (informations_de_livraison) => {
    this.setState({ informations_de_livraison });
  }

  changeTelephoneDeLivraison = (telephone_de_livraison) => {
    this.setState({ telephone_de_livraison });
  }

  changeAdresseEnregistre = (data) => {
    const { association } = this.props;
    if (data) {
      this.setState({
        adresse: association.adresse,
        code_postal: association.code_postal,
        ville: association.ville,
        pays: association.pays,
        telephone_de_livraison: association.telephone,
      });
    }
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.handleNext({ ...this.state });
  }

  renderAdresses = (association) => {
    if (association.adresse !== null && association.code_postal !== null && association.ville !== null && association.pays !== null) {
      const adresse = {
        adresse: association.adresse,
        code_postal: association.code_postal,
        ville: association.ville,
        pays: association.pays,
      }

      return [
        <div key={0} className="div-input long">
          <label>Utiliser une adresse enregistrée <sup>*</sup></label>
          <Select
            onChange={this.changeAdresseEnregistre}
            placeholder=""
          >
            <Option value={true}>{adresse.adresse}</Option>
          </Select>
        </div>,
        <p key={1} style={{ textAlign: 'center' }}>ou</p>
      ];
    }
  }

  renderCountries = (country) => {
    const countries = [
      {
        value: "UK",
        name: "Angleterre",
      },
      {
        value: "AT",
        name: "Autriche",
      },
      {
        value: "BE",
        name: "Belgique",
      },
      {
        value: "US",
        name: "États Unis",
      },
      {
        value: "CA",
        name: "Canada",
      },
      {
        value: "CN",
        name: "Chine",
      },
      {
        value: "FR",
        name: "France",
      },
      {
        value: "DE",
        name: "Allemagne",
      },
      {
        value: "IT",
        name: "Italie",
      },
      {
        value: "NL",
        name: "Hollande",
      },
      {
        value: "PT",
        name: "Portugal",
      },
      {
        value: "ES",
        name: "Espagne",
      },
      {
        value: "CH",
        name: "Suisse",
      },
    ];

    const france = [{
      value: "FR",
      name: "France",
    }]
    return (
      <Select
        onChange={(event) => this.changePays(event)}
        value={country}
        bordered={false}
        style={{ width: '100%' }}
      >
        {france.map((x, index) => {
          return <Option key={index} value={x.value}>{x.name}</Option>;
        })}
      </Select>
    );
  }

  render() {
    const { association } = this.props;
    const {
      nom,
      date_fin,
      date_debut,
      adresse,
      code_postal,
      ville,
      pays,
      informations_de_livraison,
      telephone_de_livraison,
    } = this.state;

    if (pays === null || pays === undefined) {
      return null;
    }

    return (
      <div className="nouvelle-collecte-step-container">
        <p className="titre">
          Créez votre collecte en quelques clics pour recevoir rapidement les produits dont vous avez besoin !
        </p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="div-input long">
            <label>Titre de la collecte <Tooltip title="Choisissez bien, ce titre sera vu par tous vos donateurs !"><QuestionCircleOutlined /></Tooltip> <sup>*</sup></label>
            <Input
              placeholder=""
              name="nom"
              value={nom}
              onChange={(e) => this.changeNom(e.target.value)}
            />
          </div>
          <div className="multiple-input">
            <div className="div-input">
              <label>Débute le <sup>*</sup></label>
              <Input
                type="date"
                placeholder="yyyy-mm-dd"
                name="date_debut"
                value={date_debut}
                onChange={(e) => this.changeDateDebut(e.target.value)}
                min={minDate()}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              />
            </div>
            <div className="div-input">
              <label>Termine le <sup>*</sup></label>
              <Input
                type="date"
                placeholder="yyyy-mm-dd"
                name="date_fin"
                value={date_fin}
                onChange={(e) => this.changeDateFin(e.target.value)}
                min={this.state.date_debut}
                disabled={this.state.date_debut === null}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              />
            </div>
          </div>
          <p className="titre">
            Adresse de livraison
          </p>
          {this.renderAdresses(association)}
          <div className="div-input long">
            <label>Adresse <sup>*</sup></label>
            <Input
              placeholder=""
              name="adresse"
              value={adresse}
              onChange={(e) => this.changeAdresse(e.target.value)}
            />
          </div>
          <div className="multiple-input">
            <div className="div-input">
              <label>Code Postal <sup>*</sup></label>
              <Input
                placeholder=""
                name="code_postal"
                value={code_postal}
                onChange={(e) => this.changeCodePostal(e.target.value)}
              />
            </div>
            <div className="div-input">
              <label>Ville <sup>*</sup></label>
              <Input
                placeholder=""
                name="ville"
                value={ville}
                onChange={(e) => this.changeVille(e.target.value)}
              />
            </div>
          </div>
          <div className="div-input long">
            <label>Pays <sup>*</sup></label>
            <div className="ant-input" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FBFAFA', borderRadius: 5, height: 36 }}>
              <div id="country" className={`${pays.toLowerCase()}`} style={{ height: 16 }}>
              </div>
              {this.renderCountries(pays)}
            </div>
          </div>
          <div className="div-input long">
            <label>Informations de livraison</label>
            <Input
              placeholder=""
              name="informations_de_livraison"
              value={informations_de_livraison}
              onChange={(e) => this.changeInformationsDeLivraison(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Numéro de téléphone pour la livraison <sup>*</sup></label>
            <Input
              placeholder=""
              name="telephone_de_livraison"
              value={telephone_de_livraison}
              onChange={(e) => this.changeTelephoneDeLivraison(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <p>* <i>obligatoire</i></p>
          </div>
          <div className="submit">
            <button type="submit" disabled={!nom || !date_fin || !date_debut || !adresse || !code_postal || !ville || !pays || !telephone_de_livraison}>
              Suivant <i className="fad fa-chevron-double-right"></i>
            </button>
          </div>
        </form>
      </div>
    );
  }
}
