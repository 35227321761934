import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './commentCaMarche.scss'

export default class CommentCaMarche extends Component {
  render() {
    return (
      <div className="help-container">
        <div className="feed">
          <Link to="/association/nouvelle-collecte" style={{ color: '#fff' }}>
            Créer une collecte
          </Link>
        </div>
        <div className="help-top-part">
          <div className="item">
            <img src={'https://wisdana.ams3.cdn.digitaloceanspaces.com/static/landing/5272.jpg'} alt="1" />
            <h4 className="titre">
              1. Sélectionnez les produits dont vous avez besoin en créant une collecte
            </h4>
          </div>
          <div className="item">
            <img src={'https://wisdana.ams3.cdn.digitaloceanspaces.com/static/landing/5278.jpg'} alt="2" />
            <h4 className="titre">
              2. Vos donateurs peuvent acheter les produits directement sur la collecte
            </h4>
          </div>
          <div className="item">
            <img src={'https://wisdana.ams3.cdn.digitaloceanspaces.com/static/landing/5227.jpg'} alt="3" />
            <h4 className="titre">
              3. Les produits offerts sont régulièrement livrés à l'association
            </h4>
          </div>
        </div>
        <div className="help-bottom-part">
          <p>100% gratuit et sans engagement, Wisdana fonctionne comme une liste de naissance pour association : vous choisissez les produits et les quantités dont vous avez besoin, et vos donateurs peuvent les acheter directement sur la page de collecte Wisdana. Les commandes vous sont ensuite livrées le plus régulièrement possible.</p>
          <p>Vos donateurs pourront suivre les livraisons des produits offerts, et les reçus fiscaux seront générés automatiquement (si vous y êtes éligible).</p>
          <p>Vous pouvez créer votre première liste dés maintenant et la partager avec votre communauté. Une fois que nous aurons reçu documents nécessaires, nous pourrons également mettre la collecte en ligne sur notre site. N’oubliez pas de remplir les informations nécessaires pour que nous puissions émettre les reçus fiscaux pour vos donateurs.</p>
          <p>Bonnes collectes sur Wisdana <span role="img" aria-label="smiley">😀</span>  !</p>
        </div>
      </div>
    );
  }
}
