import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { Link } from 'react-router-dom';

// import custom css
import './help.scss';

class HelpContainer extends Component {

  renderArr = (arr) => {
    return arr.map(x => {
      return (
        <Link key={x.link} to={x.link} className="link-block">
          <div className="block" style={{ backgroundImage: `url("${x.value}")` }}></div>
        </Link>
      );
    });
  }

  render() {
    const arr = [{
      link: '/association/help/info/comment-ca-marche',
      value: 'https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/info_commentcamarche.jpg'
    }, {
      link: '/association/help/astuce/collecte-reussi',
      value: 'https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/tips_collecte.jpg',
    }, {
    // }, {
    //   link: '/association/help/astuce/publication-reussi',
    //   value: 'https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/tips_texte.jpg',
    // }, {
      link: '/association/help/tuto/creer-une-collecte',
      value: 'https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/tuto_creerunecollecte.jpg',
    }, {
      link: '/association/help/tuto/remercier-donateur',
      value: 'https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/tuto_remercierdonateur.jpg',
    }];
    return (
      <div className="help-container">
        <h1 className="help-titre">
          Aide & Astuces
        </h1>
        <div className="help-block">
          {this.renderArr(arr)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({},dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(HelpContainer);
