import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import ImageUploader from '../elements/imageUploader';

const { TextArea } = Input;

export default class NewActualites extends Component {
  static propTypes = {
    postActualites: PropTypes.func.isRequired,
    data: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  }

  constructor(props) {
    super(props);
    this.state = {
      titre: null,
      text: null,
      photo: null,
      sending: false,
    }
  }

  componentDidMount() {
    if (this.props.data !== null) {
      this.setState({
        titre: this.props.data.titre,
        text: this.props.data.text,
        photo: this.props.data.photo
      });
    }
  }

  componentWillUnmount() {
    this.setState({ titre: null, text: null, photo: null, sending: false });
  }

  changeTitre = (titre) => {
    this.setState({ titre });
  }

  changeText = (text) => {
    this.setState({ text });
  }

  changePhoto = (photo) => {
    this.setState({ photo });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.setState({ sending: true });
    this.props.postActualites({ ...this.state });
  }

  render() {
    const { titre, text, photo, sending } = this.state;

    return (
      <div className="collecte-view-informations-container">
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="div-input long">
            <label>Titre</label>
            <Input
              placeholder=""
              name="titre"
              value={titre}
              onChange={(e) => this.changeTitre(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Texte</label>
            <TextArea
              rows={4}
              placeholder=""
              name="text"
              value={text}
              onChange={(e) => this.changeText(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Photo d'actualité</label>
            <ImageUploader
              changePhoto={(data) => this.changePhoto(data)}
              image={photo}
            />
          </div>
          <div className="submit">
            {sending &&
              <button type="submit" disabled={true}>
                <LoadingOutlined />
              </button>
            }
            {!sending &&
              <button type="submit" disabled={!titre}>
                Publier
              </button>
            }
          </div>
        </form>
      </div>
    );
  }
}
