export const GET_ASSOCIATION = 'containers/informations/get_association';
export const PATCH_ASSOCIATION = 'containers/informations/patch_association';
export const PATCH_NEW_PASSWORD = 'containers/informations/patch_new_password';

export const ADD_NEW_ADMIN = 'containers/informations/add_new_admin';
export const REMOVE_ADMIN = 'containers/informations/remove_admin';
export const GET_ADMINS = 'containers/informations/get_admins';

export const GET_DOCUMENTS = 'containers/informations/get_documents';
export const PATCH_DOCUMENTS = 'containers/informations/patch_documents';
