import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class RetourAide extends Component {
  render() {
    return (
      <div className="retour-aide">
        <Link to="/association/help" className="cta-help">
          <i className="fal fa-arrow-left"></i> Retour
        </Link>
      </div>
    );
  }
}
