export const GET_COLLECTE = 'containers/collecte_view/get_collecte';
export const UPDATE_COLLECTE = 'containers/collecte_view/update_collecte';

export const GET_SUIVI = 'containers/collecte_view/get_suivi';
export const UPDATE_LIVRAISON = 'containers/collecte_view/update_livraison';

export const GET_ACTUALITES = 'containers/collecte_view/get_actualites';
export const POST_ACTUALITES = 'containers/collecte_view/post_actualites';
export const PATCH_ACTUALITE = 'containers/collecte_view/patch_actualite';
export const DELETE_ACTUALITE = 'containers/collecte_view/delete_actualites';

export const GET_PRODUITS = 'containers/collecte_view/get_produits';
export const GET_PRODUCTS_LIST = 'containers/collecte_view/get_products_list';
export const POST_PRODUITS = 'containers/collecte_view/post_produits';
export const UPDATE_PRODUITS = 'containers/collecte_view/update_produits';
export const DELETE_PRODUITS = 'containers/collecte_view/delete_produits';

export const POST_REMERCIEMENT = 'containers/collecte_view/post_remerciement';

export const GET_CATEGORIES = 'containers/collecte_view/get_categories';
