import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

import ImageUploader from '../elements/imageUploader';

export default class Signature extends Component {
  static propTypes = {
    documents: PropTypes.object.isRequired,
    updateAssociation: PropTypes.func.isRequired,
  }

  static defaultProps = {
    documents: {},
    updateAssociation: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      signature: null,
      identite_signataire: null,
      fonction_signataire: null
    }
  }

  componentDidMount() {
    const { documents } = this.props;
    if (documents !== null) {
      this.setState({
        signature: documents.signature !== null ? documents.signature:null,
        fonction_signataire: documents.fonction_signataire !== null ? documents.fonction_signataire:null,
        identite_signataire: documents.identite_signataire !== null ? documents.identite_signataire:null,
      });
    }
  }

  changeSignature = (signature) => {
    this.setState({ signature });
  }

  changeFonctionSignataire = (fonction_signataire) => {
    this.setState({ fonction_signataire });
  }

  changeIdentiteSignataire = (identite_signataire) => {
    this.setState({ identite_signataire });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.updateDocuments({ ...this.state });
  }

  render() {
    const { signature, identite_signataire, fonction_signataire } = this.state;

    return (
      <div className="informations-association-container">
        <p className="titre">
          Afin de générer automatiquement les reçus fiscaux pour vos donateurs,
          <br />
          nous avons besoin de la signature du mandataire sur le tampon de l'association
        </p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <h3 className="titre" style={{ fontWeight: 600, margin: '30px auto 10px' }}>
            Signature pour les reçus fiscaux
          </h3>
          <div className="div-input long">
            <label>Nom complet du mandataire</label>
            <Input
              placeholder=""
              name="identite_signataire"
              value={identite_signataire}
              onChange={(e) => this.changeIdentiteSignataire(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Fonction du mandataire</label>
            <Input
              placeholder=""
              name="fonction_signataire"
              value={fonction_signataire}
              onChange={(e) => this.changeFonctionSignataire(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Signature et tampon de l'association sur feuille blanche</label>
            <ImageUploader
              changePhoto={(data) => this.changeSignature(data)}
              image={signature}
            />
          </div>
          <div className="submit">
            <button type="submit" disabled={!signature || !identite_signataire || !fonction_signataire}>
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    );
  }
}
