import env from "../config/env";
const { API_HOST } = env;

const API_ROUTES = {
  login: `${API_HOST}/association/login/`,
  signup: `${API_HOST}/association/signup/`,
  activation_route: `${API_HOST}/association/account-activation/`,
  token_verify: `${API_HOST}/association/api-token-verify/`,
  token_refresh: `${API_HOST}/association/api-token-refresh/`,
  password_reset: `${API_HOST}/association/reset-password/`,
  password_reset_confirm: `${API_HOST}/app/reset-password/checkToken/`,
  password_reset_done: `${API_HOST}/app/reset-password/done/`,
  admin: `${API_HOST}/association/admin/`,
  association: `${API_HOST}/association/association/`,
  collecte: `${API_HOST}/association/collecte/`,
  themes: `${API_HOST}/association/themes/`,
  categories: `${API_HOST}/association/categories/`,
  update_password: `${API_HOST}/app/update-password/`,
  signup_association: `${API_HOST}/association/signup-association/`,
  products: `${API_HOST}/association/products/`,
  email: `${API_HOST}/association/email/`,
  article: `${API_HOST}/association/article/`,
  applications: `${API_HOST}/equipe/applications/`,
};

export default API_ROUTES;
