import {
	CREATE_COLLECTE,
  GET_THEMES,
  GET_PRODUCTS,
	GET_CATEGORIES,
	UPDATE_COLLECTE,
	SUGGESTION,
	GET_ASSOCIATION,
} from './types';

const initialState = {
  collecte: null,
  themes: null,
  products: null,
	categories: null,
	update_collecte: false,
	suggestion: null,
	association: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case CREATE_COLLECTE:
			return { ...state, collecte: action.payload };
    case GET_THEMES:
      return { ...state, themes: action.payload };
    case GET_PRODUCTS:
      return { ...state, products: action.payload };
		case UPDATE_COLLECTE:
			return { ...state, update_collecte: action.payload };
		case GET_CATEGORIES:
			return { ...state, categories: action.payload };
		case SUGGESTION:
			return { ...state, suggestion: action.payload };
		case GET_ASSOCIATION:
			return { ...state, association: action.payload };
		default:
			return { ...state };
	}

}
