import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { message, Modal, Drawer } from "antd";

import { parse } from "query-string";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  getCollecte,
  updateCollecte,
  getSuivi,
  updateLivraison,
  getActualites,
  postActualites,
  patchActualite,
  deleteActualite,
  getCategories,
  getProduits,
  getProductsListByName,
  getProductsList,
  addProduit,
  updateProduit,
  deleteProduit,
  remercierUtilisateur,
  refreshRemerciement,
  refresh,
} from "./actions";

// import custom css
import "./collecteView.scss";
import "../../components/collecte_view/style.scss";

import Menu from "../../components/elements/menu";

import Informations from "../../components/collecte_view/informations";
import Products from "../../components/collecte_view/products";
import Suivi from "../../components/collecte_view/suivi";
import Actualites from "../../components/collecte_view/actualites";
import NewActualites from "../../components/collecte_view/new_actualites";
import DetailsClient from "../../components/collecte_view/detailsClient";
import RecuFiscaux from "../../components/collecte_view/recu_fiscaux";

import env from "../../config/env";
const { APP_URL } = env;

const { confirm } = Modal;
let hide = null;

class CollecteViewContainer extends Component {
  static propTypes = {
    collecteId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    collecteId: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      menu_selected: 2,
      add_new_update: false,
      order_selected: null,
      update_actualite: false,
      actualite_id: null,
      updating: false,
    };
  }

  componentDidMount() {
    if (this.props.collecteId !== null) {
      this.props.getCollecte(this.props.collecteId);
      this.props.getProduits(this.props.collecteId);
      this.props.getSuivi(this.props.collecteId);
      this.props.getActualites(this.props.collecteId);
      this.props.getCategories();
      const query = parse(window.location.search);
      if (query.menu === "suivi") {
        this.setState({ menu_selected: 1 });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.collecteId === null && this.props.collecteId !== null) {
      this.props.getCollecte(this.props.collecteId);
      this.props.getProduits(this.props.collecteId);
      this.props.getSuivi(this.props.collecteId);
      this.props.getActualites(this.props.collecteId);
    }

    if (
      prevProps.collecte !== this.props.collecte &&
      prevProps.collecte !== null
    ) {
      message.success({
        content: "Vos changements ont bien été effectués",
        duration: 1,
      });
    }

    if (
      prevProps.produits !== this.props.produits &&
      prevProps.produits !== null
    ) {
      message.success({
        content: "Vos changements ont bien été effectués",
        duration: 1,
      });
    }

    if (prevProps.remerciement === null && this.props.remerciement === true) {
      message.success({
        content: "Merci pour le message",
        duration: 1,
      });
      this.setState({ order_selected: null });
      this.props.refreshRemerciement();
    }

    if (
      prevProps.actualites !== this.props.actualites &&
      prevProps.actualites !== null &&
      this.state.updating === true
    ) {
      hide();
      this.setState({ updating: false });
    }
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  changeMenuSelected = (menu_selected) => {
    this.setState({ menu_selected });
  };

  copyCodeToClipboard = () => {
    const el = this.input;
    el.select();
    document.execCommand("copy");
    message.success("Lien copié");
  };

  handleAddNewUpdate = (add_new_update) => {
    this.setState({
      add_new_update,
      update_actualite: false,
      actualite_id: null,
    });
  };

  handlePatchUpdate = (actualite_id) => {
    this.setState({
      addNewUpdate: false,
      update_actualite: true,
      actualite_id,
    });
  };

  closeDetailsClient = () => {
    this.setState({ order_selected: null });
  };

  openDetailsClient = (id, orders) => {
    const order_selected = orders.find((x) => x.id === id);
    this.setState({ order_selected });
  };

  renderMenu = (menu_selected) => {
    const array = [
      {
        key: 1,
        value: "Suivi",
      },
      {
        key: 2,
        value: "Informations",
      },
      {
        key: 3,
        value: "Produits",
      },
      {
        key: 4,
        value: "Actualités",
      },
      {
        key: 5,
        value: "Reçus Fiscaux",
      },
    ];

    return (
      <Menu
        data={array}
        active_menu={menu_selected}
        handleActiveMenu={(data) => this.changeMenuSelected(data)}
      />
    );
  };

  renderPart = (menu_selected) => {
    const { collecte, produits, suivi, actualites, categories, produits_list } =
      this.props;
    const { updating } = this.state;

    switch (menu_selected) {
      case 1:
        if (suivi === null) {
          return null;
        }
        return (
          <Suivi
            suivi={suivi}
            updateLivraison={(id, data) =>
              this.props.updateLivraison(this.props.collecteId, id, data)
            }
            addNewUpdate={() => this.handleAddNewUpdate(true)}
            openDetailsClient={(id) => this.openDetailsClient(id, suivi.orders)}
          />
        );
      case 2:
        return (
          <Informations
            collecte={collecte}
            updateCollecte={(data) =>
              this.props.updateCollecte(this.props.collecteId, data)
            }
          />
        );
      case 3:
        if (produits === null && suivi === null && categories === null) {
          return null;
        }
        return (
          <Products
            products={produits}
            color={"#2b8bbc"}
            terminer={collecte.terminer}
            suivi={suivi}
            addProduit={(data) =>
              this.props.addProduit(this.props.collecteId, data)
            }
            getProductsByName={(data) => this.props.getProductsListByName(data)}
            getProducts={(data) => this.props.getProductsList(data)}
            updateProduit={(produit_id, data) =>
              this.props.updateProduit(this.props.collecteId, produit_id, data)
            }
            deleteProduit={(produit_id) =>
              this.props.deleteProduit(this.props.collecteId, produit_id)
            }
            categories={categories}
            produits_list={produits_list}
          />
        );
      case 4:
        if (actualites === null) {
          return null;
        }
        return (
          <Actualites
            actualites={actualites}
            addNewUpdate={() => this.handleAddNewUpdate(true)}
            updating={updating}
            patchActualite={(id) => this.handlePatchUpdate(id)}
            deleteActualite={(id) =>
              this.props.deleteActualite(this.props.collecteId, id)
            }
          />
        );
      case 5:
        if (suivi === null) {
          return null;
        }
        return (
          <RecuFiscaux
            orders={suivi.orders.filter((x) => x.recu_fiscaux !== null)}
          />
        );
      default:
        return <p>Informations</p>;
    }
  };

  renderDate = (collecte) => {
    const date_debut = new Date(collecte.date_debut);
    const date_fin = new Date(collecte.date_fin);

    const date_debut_day =
      date_debut.getDate() < 10
        ? `0${date_debut.getDate()}`
        : `${date_debut.getDate()}`;
    const date_debut_month =
      date_debut.getMonth() < 9
        ? `0${date_debut.getMonth() + 1}`
        : `${date_debut.getMonth() + 1}`;

    const date_fin_day =
      date_fin.getDate() < 10
        ? `0${date_fin.getDate()}`
        : `${date_fin.getDate()}`;
    const date_fin_month =
      date_fin.getMonth() < 9
        ? `0${date_fin.getMonth() + 1}`
        : `${date_fin.getMonth() + 1}`;

    return `du ${date_debut_day}/${date_debut_month}/${date_debut.getFullYear()} au ${date_fin_day}/${date_fin_month}/${date_fin.getFullYear()}`;
  };

  terminateCollecte = () => {
    const { updateCollecte, collecteId } = this.props;
    confirm({
      title: "Êtes vous sûr de vouloir annuler cette collecte ?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Cette action est irréversible et la collecte ne sera plus visible par nos donateurs",
      onOk() {
        updateCollecte(collecteId, { terminer: true });
      },
      onCancel() {},
    });
  };

  postActualites = (collecteId, data) => {
    this.props.postActualites(collecteId, data);
    this.setState({ add_new_update: false });
  };

  patchActualite = (collecteId, actualite_id, data) => {
    this.props.patchActualite(collecteId, actualite_id, data);
    this.setState({
      add_new_update: false,
      update_actualite: false,
      actualite_id: null,
      updating: true,
    });
    hide = message.loading("Modification de l'actualité en cours", 0);
  };

  render() {
    const {
      menu_selected,
      add_new_update,
      order_selected,
      actualite_id,
      update_actualite,
    } = this.state;
    const { collecte, collecteId, actualites } = this.props;

    if (collecte === null) {
      return null;
    }

    return (
      <div className="collecte-view-container">
        <div className="collecte-view-buttons">
          <div className="collecte-view-button-view-collecte">
            <a
              href={`${APP_URL}/app/collecte/${collecteId}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#fff" }}
            >
              Voir la collecte
            </a>
          </div>
          {!collecte.terminer && (
            <div
              className="collecte-view-button-annuler"
              onClick={this.terminateCollecte}
            >
              Annuler cette collecte
            </div>
          )}
        </div>
        <div className="collecte-view-titre-container">
          <h1 className="collecte-view-titre">{collecte.nom}</h1>
          {!collecte.terminer && (
            <p className="collecte-view-sous-titre">
              {this.renderDate(collecte)}
            </p>
          )}
          {collecte.terminer && (
            <p className="collecte-view-sous-titre">Collecte terminée</p>
          )}
          <div className="collecte-view-partage">
            <p className="lien-text">Lien de partage</p>
            <div className="lien" onClick={() => this.copyCodeToClipboard()}>
              <input
                ref={(input) => (this.input = input)}
                value={`${APP_URL}/app/collecte/${collecte.id}`}
              />
              <i className="fad fa-clipboard"></i>
            </div>
          </div>
        </div>
        {this.renderMenu(menu_selected)}
        {this.renderPart(menu_selected)}
        <Drawer
          title={`Publier un message pour vos donateurs`}
          placement="right"
          closable={true}
          onClose={() => this.handleAddNewUpdate(false)}
          visible={add_new_update}
          className="cart-drawer"
          headerStyle={{ textAlign: "center" }}
          destroyOnClose={true}
        >
          <NewActualites
            data={null}
            postActualites={(data) => this.postActualites(collecteId, data)}
          />
        </Drawer>
        <Drawer
          title={`Publier un message pour vos donateurs`}
          placement="right"
          closable={true}
          onClose={() => this.handleAddNewUpdate(false)}
          visible={update_actualite}
          className="cart-drawer"
          headerStyle={{ textAlign: "center" }}
          destroyOnClose={true}
        >
          <NewActualites
            data={
              actualite_id !== null &&
              actualites !== null &&
              actualites.find((x) => x.id === actualite_id)
            }
            postActualites={(data) =>
              this.patchActualite(collecteId, actualite_id, data)
            }
          />
        </Drawer>
        <Drawer
          title={`Details de la commande #${
            order_selected !== null ? order_selected.id : 0
          }`}
          placement="right"
          closable={true}
          onClose={() => this.closeDetailsClient()}
          visible={order_selected !== null ? true : false}
          className="suivi-drawer"
          headerStyle={{ textAlign: "center" }}
          destroyOnClose={true}
        >
          {order_selected !== null && (
            <DetailsClient
              order={order_selected}
              remercierUtilisateur={(data) =>
                this.props.remercierUtilisateur(data)
              }
            />
          )}
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collecte: state.collecte_view.collecte,
    actualites: state.collecte_view.actualites,
    suivi: state.collecte_view.suivi,
    produits: state.collecte_view.produits,
    produits_list: state.collecte_view.produits_list,
    categories: state.collecte_view.categories,
    remerciement: state.collecte_view.remerciement,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCollecte,
      updateCollecte,
      getSuivi,
      updateLivraison,
      getActualites,
      postActualites,
      patchActualite,
      deleteActualite,
      getCategories,
      getProduits,
      getProductsListByName,
      getProductsList,
      addProduit,
      updateProduit,
      deleteProduit,
      refresh,
      remercierUtilisateur,
      refreshRemerciement,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollecteViewContainer);
