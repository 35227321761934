import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { Link } from 'react-router-dom';

import { Drawer } from 'antd';

import { trackWindowScroll } from 'react-lazy-load-image-component';

import {
  getCategories,
  getProduits,
  getProductsByName,
  sendSuggestion,
  toggleSuggestion,
  getAssociation,
  refresh,
} from './actions';

import "./produits.scss";
import "../../components/produits/style.scss";

import Products from '../../components/produits/produits';
import Suggestion from '../../components/nouvelle_collecte/suggestion';;

class ProduitsContainer extends Component {
  static propTypes = {
    categories: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    produits: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    suggestion: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.bool.isRequired,
    ]),
    association: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ])
  }

  static defaultProps = {
    categories: null,
    produits: null,
    suggestion: null,
    association: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      suggestion_drawer: false,
    }
  }

  componentDidMount() {
    this.props.getCategories();
    this.props.getAssociation();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.suggestion === null && this.props.suggestion === true) {
      this.setState({ suggestion_drawer: false });
      this.props.toggleSuggestion();
    }
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  toggleDrawerSuggestion = (bool) => {
    this.setState({ drawer: false, suggestion_drawer: bool });
  }

  render() {
    const { categories, produits, scrollPosition, association } = this.props;
    const { suggestion_drawer } = this.state;

    if (association === null) {
      return null;
    }

    return (
      <div className="produits-container">
        <div className="feed">
          <Link to="/association/nouvelle-collecte" style={{ color: '#fff' }}>
            Créer une collecte
          </Link>
        </div>
        <h1 style={{ fontSize: '2em', color: '#2b8bbc', textAlign: 'center' }}>Notre catalogue produits</h1>
        <p className="titre">
          Vous ne trouvez pas le produit que vous cherchez ? Cliquez <span
            style={{ fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => this.toggleDrawerSuggestion(true)}
          >ici</span>
        </p>
        <Products
          products={produits}
          categories={categories}
          getProducts={(data) => this.props.getProduits(data)}
          getProductsByName={(data) => this.props.getProductsByName(data)}
          scrollPosition={scrollPosition}
        />
        <Drawer
          title="Suggestion de produit"
          placement="right"
          closable={true}
          onClose={() => this.toggleDrawerSuggestion(false)}
          visible={suggestion_drawer}
          className="cart-drawer"
          headerStyle={{ textAlign: 'center' }}
          destroyOnClose={true}
        >
          <Suggestion
            sendSuggestion={(data) => this.props.sendSuggestion(data)}
          />
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.produits.categories,
    produits: state.produits.produits,
    suggestion: state.produits.suggestion,
    association: state.produits.association,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getProduits,
    getCategories,
    getProductsByName,
    sendSuggestion,
    toggleSuggestion,
    getAssociation,
    refresh,
  },dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(trackWindowScroll(ProduitsContainer));
