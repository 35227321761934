import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { Redirect } from 'react-router-dom';

import {
  createCollecte,
  updateCollecte,
  getCategories,
  getThemes,
  getProducts,
  getProductsByName,
  sendSuggestion,
  toggleSuggestion,
  getAssociation,
  refresh
} from './actions';

import NouvelleCollecteStep1 from '../../components/nouvelle_collecte/nouvelle_collecte_step1';
import NouvelleCollecteStep2 from '../../components/nouvelle_collecte/nouvelle_collecte_step2';
import NouvelleCollecteStep3 from '../../components/nouvelle_collecte/nouvelle_collecte_step3';

import Tracker from '../../components/elements/tracker';

// import custom css
import './nouvelle_collecte.scss';
import '../../components/nouvelle_collecte/style.scss';

class NouvelleCollecteContainer extends Component {
  static propTypes = {
    collecte: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    themes: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    products: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    categories: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.array.isRequired,
    ]),
    update_collecte: PropTypes.bool.isRequired,
    association: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  };

  static defaultProps = {
    collecte: null,
    themes: null,
    products: null,
    association: null,
    update_collecte: false,
    categories: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      step1: null,
      collecte_id: null,
    }
  }

  componentDidMount() {
    this.props.getThemes();
    this.props.getCategories();
    this.props.getAssociation();

    if (this.props.collecteId !== null) {
      this.setState({
        collecte_id: this.props.collecteId,
        step: 3
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.collecteId === null && this.props.collecteId !== null) {
      this.setState({ collecte_id: this.props.collecteId, step: 3 });
    }

    return true;
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  handleStep1 = (data) => {
    this.setState({ step1: data, step: 2 });
  }

  handleStep2 = (data) => {
    const object = {
      ...this.state.step1,
      theme: data.theme,
      description: data.description,
      photo: data.photo,
    };

    this.props.createCollecte(object);
  }

  handleStep3 = (data) => {
    this.props.updateCollecte(data, this.state.collecte_id);
  }

  renderStep = (step) => {
    const { themes, products, association, categories, collecte, suggestion } = this.props;

    switch (step) {
      case 1:
        return <NouvelleCollecteStep1 handleNext={(data) => this.handleStep1(data)} association={association} data={this.state.step1} />;
      case 2:
        if (themes !== null) {
          return <NouvelleCollecteStep2 themes={themes} handleNext={(data) => this.handleStep2(data)} handleBack={() => this.setState({ step: 1 })} />;
        }
        return null;
      case 3:
        return <NouvelleCollecteStep3
          categories={categories}
          products={products}
          handleNext={(data) => this.handleStep3(data)}
          getProducts={(data) => this.props.getProducts(data)}
          getProductsByName={(data) => this.props.getProductsByName(data)}
          collecte={collecte}
          sendSuggestion={(data) => this.props.sendSuggestion(data)}
          suggestion={suggestion}
          toggleSuggestion={() => this.props.toggleSuggestion()}
          />;
      default:
        return <NouvelleCollecteStep1 />;
    }
  }

  render() {
    const { association } = this.props;
    const { step, collecte_id } = this.state;
    const percent = [33, 66, 100];

    if (this.props.collecte !== null && collecte_id === null) {
      return <Redirect to={`/association/nouvelle-collecte/${this.props.collecte.id}`} />;
    }

    if (this.props.update_collecte) {
      return <Redirect to={`/association/collecte/${collecte_id}`} />
    }

    if (association === null) {
      return null;
    }

    return (
      <div className="nouvelle-collecte-container">
        <div className="nouvelle-collecte-tracker">
          <Tracker
            color={"#2b8bbc"}
            percent={percent[step - 1]}
            height={10}
            radius={5}
          />
        </div>
        <p>
          Étape <span style={{ color: "#2b8bbc", fontWeight: 600 }}>{step}</span> sur 3
        </p>
        {this.renderStep(step)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collecte: state.nouvelle_collecte.collecte,
    themes: state.nouvelle_collecte.themes,
    products: state.nouvelle_collecte.products,
    update_collecte: state.nouvelle_collecte.update_collecte,
    categories: state.nouvelle_collecte.categories,
    suggestion: state.nouvelle_collecte.suggestion,
    association: state.nouvelle_collecte.association,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createCollecte,
    updateCollecte,
    getCategories,
    getThemes,
    getProducts,
    getProductsByName,
    getAssociation,
    sendSuggestion,
    toggleSuggestion,
    refresh,
  },dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(NouvelleCollecteContainer);
