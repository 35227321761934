import {
	GET_ASSOCIATION,
	PATCH_NEW_PASSWORD,
	ADD_NEW_ADMIN,
	REMOVE_ADMIN,
	GET_ADMINS,
	GET_DOCUMENTS,
	PATCH_DOCUMENTS,
	PATCH_ASSOCIATION,
} from './types';

const initialState = {
  association: null,
	error: null,
	success: null,
	admins: null,
	documents: null,
}

function removeAdmin(state, admin_id) {
	if (state.admins === null) {
		return { ...state };
	}
	const new_admins = state.admins.filter(x => x.id !== admin_id);

	return { ...state, admins: new_admins };
}

function addNewAdmin(state, admin) {
	if (state.admins === null) {
		return { ...state, admins: [admin]}
	}
	const new_admins = [ ...state.admins, admin];

	return { ...state, admins: new_admins };
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_ASSOCIATION:
			return { ...state, association: action.payload };
		case PATCH_ASSOCIATION:
			return { ...state, association: action.payload };
		case PATCH_NEW_PASSWORD:
			return { ...state, error: action.error, success: action.success };
		case REMOVE_ADMIN:
			return removeAdmin(state, action.payload);
		case ADD_NEW_ADMIN:
			return addNewAdmin(state, action.payload);
		case GET_ADMINS:
			return { ...state, admins: action.payload };
		case GET_DOCUMENTS:
			return { ...state, documents: action.payload };
		case PATCH_DOCUMENTS:
			return { ...state, documents: action.payload };
		default:
			return { ...state };
	}

}
