import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

import { message } from 'antd';

export default class Suggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produit: null,
    }
  }

  static propTypes = {
    sendSuggestion: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sendSuggestion: () => {},
  };

  changeProduit = (produit) => {
    this.setState({ produit });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.sendSuggestion({ suggestion: this.state.produit });
    message.success({
      content: 'Le produit a bien été suggeré aux staff',
      duration: 1,
    });
  }

  render() {
    return (
      <form className="nouvelle-collecte-step-container" style={{ margin: '0px auto' }} onSubmit={e => this.handleSubmit(e)}>
        <p style={{ textAlign: 'center' }}>
          Vous n’avez pas trouvé ce dont vous aviez besoin ?
          <br />
          Indiquez-nous le(s) produit(s) à ajouter : de nouveaux produits sont disponibles chaque semaine grâce à vos suggestions.
        </p>
        <div className="div-input">
          <label>Nom du produit</label>
          <Input
            placeholder=""
            name="produit"
            value={this.state.produit}
            onChange={(e) => this.changeProduit(e.target.value)}
          />
        </div>
        <div className="submit">
          <button type="submit" disabled={!this.state.produit}>Envoyer</button>
        </div>
      </form>
    );
  }
}
