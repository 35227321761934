import axios from 'axios';

import {
	AUTH_ACTIVATION,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const ACTIVATE_URL = API_ROUTES.activation_route;


export function confirmActivation(location) {
	return function(dispatch) {
		axios.post(`${ACTIVATE_URL}`, location)
		.then((response) => {
			localStorage.setItem('token', response.data.token);

			dispatch({
				type: AUTH_ACTIVATION,
				authed: true,
				status:true,
				error: null,
				user: response.data.customer
			});
		})
		.catch((error) => {
			dispatch({ type: AUTH_ACTIVATION, user: null, authed: false, status:false, error: 'Ce lien est expiré' });
		});
	}
}
