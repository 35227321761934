import React, { Component } from 'react';

import './collecteReussi.scss';

export default class CollecteReussi extends Component {
  render() {
    return (
      <div className="collecte-reussi">
        <div className="collecte-reussi-titre">
          <p className="collecte-reussi-titre-number">
            4
          </p>
          <p className="collecte-reussi-titre-text">
            conseils pour une
            <br />
            <span style={{ fontWeight: 700, color: "#000" }}>collecte réussie</span>
          </p>
        </div>
        <div className="collecte-reussi-round"></div>
        <div className="collecte-reussi-body">
          <div className="collecte-reussi-timeline first-child"></div>
          <div className="collecte-reussi-body-separator"></div>
          <div className="collecte-reussi-body-paragraph">
            <h1 className="collecte-reussi-body-paragraph-titre">Complétez</h1>
            <p className="collecte-reussi-body-paragraph-text">votre profil avec les infos importantes<br />(présentation, photos, déduction fiscale, etc)</p>
          </div>
          <p className="collecte-reussi-body-number mobile">01</p>
          <p className="collecte-reussi-body-number desktop">01</p>
          <div className="collecte-reussi-body-image-1"></div>
        </div>
        <div className="collecte-reussi-body">
          <div className="collecte-reussi-timeline"></div>
          <img className="collecte-reussi-body-image" src="https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/02_variez.png" alt="variez" />
          <p className="collecte-reussi-body-number desktop">02</p>
          <p className="collecte-reussi-body-number mobile">02</p>
          <div className="collecte-reussi-body-paragraph">
            <h1 className="collecte-reussi-body-paragraph-titre">Variez</h1>
            <p className="collecte-reussi-body-paragraph-text">le choix des produits,<br />il en faut pour toutes les bourses !</p>
          </div>
          <div className="collecte-reussi-body-separator"></div>
        </div>
        <div className="collecte-reussi-body">
          <div className="collecte-reussi-timeline"></div>
          <div className="collecte-reussi-body-separator"></div>
          <div className="collecte-reussi-body-paragraph">
            <h1 className="collecte-reussi-body-paragraph-titre">Partagez</h1>
            <p className="collecte-reussi-body-paragraph-text">la collecte sur vos réseaux sociaux,<br />avec une petite explication</p>
          </div>
          <p className="collecte-reussi-body-number desktop">03</p>
          <p className="collecte-reussi-body-number mobile">03</p>
          <img className="collecte-reussi-body-image" src="https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/03_partagez.gif" alt="partagez" />
        </div>
        <div className="collecte-reussi-body">
          <div className="collecte-reussi-timeline last-child"></div>
          <div className="collecte-reussi-timeline last-last-child"></div>
          <img className="collecte-reussi-body-image" src="https://wisdana.ams3.cdn.digitaloceanspaces.com/static/aide/04_remerciez.jpg" alt="remerciez" />
          <p className="collecte-reussi-body-number desktop">04</p>
          <p className="collecte-reussi-body-number mobile">04</p>
          <div className="collecte-reussi-body-paragraph">
            <h1 className="collecte-reussi-body-paragraph-titre">Remerciez</h1>
            <p className="collecte-reussi-body-paragraph-text">vos donateurs en partageant une photo de la livraison sur vos réseaux sociaux, et sur la collecte !</p>
          </div>
        </div>
      </div>
    );
  }
}
