import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { trackWindowScroll }from 'react-lazy-load-image-component';

import Collecte from '../elements/collecteCard';

class Collectes extends Component {
  static propTypes = {
    collectes: PropTypes.arrayOf(
      PropTypes.shape({
        association: PropTypes.shape({
          nom: PropTypes.string.isRequired,
          adresse: PropTypes.string.isRequired,
          latitude: PropTypes.string.isRequired,
          longitude: PropTypes.string.isRequired,
          adresse_de_livraison: PropTypes.string.isRequired,
          photo: PropTypes.string,
          description: PropTypes.string.isRequired,
          telephone: PropTypes.string.isRequired,
          nombre_de_membre: PropTypes.number,
          site_web: PropTypes.string,
          grand_label_associatif: PropTypes.string.isRequired,
          logo: PropTypes.string,
          defiscalisable: PropTypes.bool.isRequired,
          defiscalisation_percent: PropTypes.number.isRequired,
        }),
        date_debut: PropTypes.string.isRequired,
        date_fin: PropTypes.string,
        description: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        lieu_de_livraison: PropTypes.string.isRequired,
        nom: PropTypes.string.isRequired,
        percent: PropTypes.shape({
          price: PropTypes.number.isRequired,
          quantity_available: PropTypes.number.isRequired,
          total: PropTypes.number.isRequired,
        }),
        photo: PropTypes.string,
        promue: PropTypes.bool.isRequired,
        sous_theme: PropTypes.number.isRequired,
        terminer: PropTypes.bool.isRequired,
        theme: PropTypes.shape({
          name: PropTypes.string.isRequired,
          couleur: PropTypes.string.isRequired,
          image: PropTypes.string.isRequired,
          image_selected: PropTypes.string.isRequired,
        })
      }),
    ),
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    deleteCollecte: PropTypes.func.isRequired,
  };

  static defaultProps = {
    collectes: [],
    scrollPosition: {},
    deleteCollecte: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      tous: 0,
    }
  }

  handleTous = (tous) => {
    this.setState({ tous });
  }

  sort = (arr, tous) => {
    switch(tous) {
      case 0:
        return arr;
      case 1:
        return arr.filter(x => x.terminer === false);
      case 2:
        return arr.filter(x => x.terminer === true);
      default:
        return arr;
    }
  }

  renderCollectes = (collectes, scrollPosition, tous) => {
    const sorted_collectes = this.sort(collectes, tous);

    return sorted_collectes.map(x => {
      return <Collecte key={x.id} deleteCollecte={(data) => this.props.deleteCollecte(data)} collecte={x} scrollPosition={scrollPosition} />
    });
  }

  renderFiltres = (tous) => {
    const arr = [
      {
        key: 0,
        value: 'Toutes',
      },
      {
        key: 1,
        value: 'En cours',
      },
      {
        key: 2,
        value: 'Terminées'
      },
    ];

    return arr.map(x => {
      return (
        <p
          key={x.key}
          className={x.key === tous ? "collectes-filtre active":"collectes-filtre"}
          onClick={() => this.handleTous(x.key)}
        >
          {x.value}
        </p>
      );
    });
  }

  render() {
    const { collectes, scrollPosition} = this.props;
    const { tous } = this.state;

    return (
      <div className="collectes">
        <div className="collectes-filtre-container">
          <div className="collectes-filtre-block">
            {this.renderFiltres(tous)}
          </div>
          {<div className="cta">
            <Link to="/association/nouvelle-collecte" className="text">
              <i className="fal fa-plus"></i> Nouvelle collecte
            </Link>
          </div>}
        </div>
        <div className="collectes-block">
          {this.renderCollectes(collectes, scrollPosition, tous)}
        </div>
      </div>
    );
  }
}

export default trackWindowScroll(Collectes);
