import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, Select, message } from 'antd';

import FileUploader from '../elements/fileUploader';

const { Option } = Select;

let hide = null;

const formeJuridique = [
  {
    key: 'association',
    value: 'Association',
  },
  {
    key: 'fondation',
    value: 'Fondation',
  },
  {
    key: 'fonds de dotation',
    value: 'Fonds de dotation',
  },
];

const typeAssociation = [
  {
    key: 'loi 1901',
    value: 'Loi 1901',
  },
  {
    key: 'loi 1905',
    value: 'Loi 1905',
  },
  {
    key: 'loi 1908',
    value: 'Loi 1908',
  },
  {
    key: 'Code civil Alsace-Moselle',
    value: 'Code civil Alsace-Moselle',
  }
];

export default class Association extends Component {
  static propTypes = {
    documents: PropTypes.object.isRequired,
    updateAssociation: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      last_name: null,
      first_name: null,
      email: null,
      telephone: null,
      adresse: null,
      code_postal: null,
      ville: null,
      pays: 'FR',
      responsable_id: null,
      raison_sociale: null,
      statut_juridique: null,
      type_association: null,
      numero_rna: null,
      objet_structure: null,
      parution_journal_officiel: null,
      statuts_signe: null,
      liste_signee_membre: null,
      change_doc: false,
      doc_type: null,
    }
  }

  componentDidMount() {
    const { documents } = this.props;
    if (documents !== null) {
      this.setState({
        last_name: documents.last_name !== null ? documents.last_name:'',
        first_name: documents.first_name !== null ? documents.first_name:'',
        email: documents.email !== null ? documents.email:'',
        telephone: documents.telephone !== null ? documents.telephone:'',
        adresse: documents.adresse !== null ? documents.adresse:'',
        code_postal: documents.code_postal !== null ? documents.code_postal:'',
        ville: documents.ville !== null ? documents.ville:'',
        pays: documents.pays !== null ? documents.pays:'FR',
        responsable_id: documents.responsable_id !== null ? documents.responsable_id:null,
        raison_sociale: documents.raison_sociale !== null ? documents.raison_sociale:null,
        statut_juridique: documents.statut_juridique !== null ? documents.statut_juridique:null,
        type_association: documents.type_association !== null ? documents.type_association:null,
        numero_rna: documents.numero_rna !== null ? documents.numero_rna:null,
        objet_structure: documents.objet_structure !== null ? documents.objet_structure:null,
        parution_journal_officiel: documents.parution_journal_officiel !== null ? documents.parution_journal_officiel:null,
        statuts_signe: documents.statuts_signe !== null ? documents.statuts_signe:null,
        liste_signee_membre: documents.liste_signee_membre !== null ? documents.liste_signee_membre:null,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.documents !== null && prevProps.documents !== this.props.documents && this.props.documents !== null) {
      const { documents } = this.props;
      if (hide !== null) {
        hide();
      }

      if (this.state.change_doc === true && this.state.doc_type !== null) {
        switch(this.state.doc_type) {
          case 'responsable_id':
            this.setState({ responsable_id: documents.responsable_id });
            break;
          case 'parution_journal_officiel':
            this.setState({ parution_journal_officiel: documents.parution_journal_officiel });
            break;
          case 'statuts_signe':
            this.setState({ statuts_signe: documents.statuts_signe });
            break;
          case 'liste_signee_membre':
            this.setState({ liste_signee_membre: documents.liste_signee_membre });
            break;
          default:
            this.setState({ responsable_id: documents.responsable_id });
            break;
        }
      } else {
        this.setState({
          last_name: documents.last_name !== null ? documents.last_name:'',
          first_name: documents.first_name !== null ? documents.first_name:'',
          email: documents.email !== null ? documents.email:'',
          telephone: documents.telephone !== null ? documents.telephone:'',
          adresse: documents.adresse !== null ? documents.adresse:'',
          code_postal: documents.code_postal !== null ? documents.code_postal:'',
          ville: documents.ville !== null ? documents.ville:'',
          pays: documents.pays !== null ? documents.pays:'FR',
          responsable_id: documents.responsable_id !== null ? documents.responsable_id:null,
          raison_sociale: documents.raison_sociale !== null ? documents.raison_sociale:null,
          statut_juridique: documents.statut_juridique !== null ? documents.statut_juridique:null,
          type_association: documents.type_association !== null ? documents.type_association:null,
          numero_rna: documents.numero_rna !== null ? documents.numero_rna:null,
          objet_structure: documents.objet_structure !== null ? documents.objet_structure:null,
          parution_journal_officiel: documents.parution_journal_officiel !== null ? documents.parution_journal_officiel:null,
          statuts_signe: documents.statuts_signe !== null ? documents.statuts_signe:null,
          liste_signee_membre: documents.liste_signee_membre !== null ? documents.liste_signee_membre:null,
        });
      }
    }
  }

  changeLastName = (last_name) => {
    this.setState({ last_name });
  }

  changeFirstName = (first_name) => {
    this.setState({ first_name });
  }

  changeEmail = (email) => {
    this.setState({ email });
  }

  changeTelephone = (telephone) => {
    this.setState({ telephone });
  }

  changeAdresse = (adresse) => {
    this.setState({ adresse });
  }

  changeCodePostal = (code_postal) => {
    this.setState({ code_postal });
  }

  changeVille = (ville) => {
    this.setState({ ville });
  }

  changePays = (pays) => {
    this.setState({ pays });
  }

  changeResponsableId = (responsable_id) => {
    this.props.updateDocuments({ responsable_id });
    this.setState({ responsable_id, change_doc: true, doc_type: 'responsable_id' });
    hide = message.loading('Envoi des documents', 0);
  }

  changeRaisonSociale = (raison_sociale) => {
    this.setState({ raison_sociale });
  }

  changeStatutJuridique = (statut_juridique) => {
    this.setState({ statut_juridique });
  }

  changeTypeAssociation = (type_association) => {
    this.setState({ type_association });
  }

  changeNumeroRNA = (numero_rna) => {
    this.setState({ numero_rna });
  }

  changeObjetStructure = (objet_structure) => {
    this.setState({ objet_structure });
  }

  changeParutionJournalOfficiel = (parution_journal_officiel) => {
    this.props.updateDocuments({ parution_journal_officiel });
    this.setState({ parution_journal_officiel, change_doc: true, doc_type: 'parution_journal_officiel' });
    hide = message.loading('Envoi des documents', 0);
  }

  changeStatutsSigne = (statuts_signe) => {
    this.props.updateDocuments({ statuts_signe });
    this.setState({ statuts_signe, change_doc: true, doc_type: 'statuts_signe' });
    hide = message.loading('Envoi des documents', 0);
  }

  changeListeSigneeMembre = (liste_signee_membre) => {
    this.props.updateDocuments({ liste_signee_membre });
    this.setState({ liste_signee_membre, change_doc: true, doc_type: 'liste_signee_membre' });
    hide = message.loading('Envoi des documents', 0);
  }

  renderCountries = (country) => {
    const countries = [
      {
        value: "UK",
        name: "Angleterre",
      },
      {
        value: "AT",
        name: "Autriche",
      },
      {
        value: "BE",
        name: "Belgique",
      },
      {
        value: "US",
        name: "États Unis",
      },
      {
        value: "CA",
        name: "Canada",
      },
      {
        value: "CN",
        name: "Chine",
      },
      {
        value: "FR",
        name: "France",
      },
      {
        value: "DE",
        name: "Allemagne",
      },
      {
        value: "IT",
        name: "Italie",
      },
      {
        value: "NL",
        name: "Hollande",
      },
      {
        value: "PT",
        name: "Portugal",
      },
      {
        value: "ES",
        name: "Espagne",
      },
      {
        value: "CH",
        name: "Suisse",
      },
    ];
    return (
      <Select
        onChange={(event) => this.changePays(event)}
        value={country}
        bordered={false}
        style={{ width: '100%' }}
      >
        {countries.map((x, index) => {
          return <Option key={index} value={x.value}>{x.name}</Option>;
        })}
      </Select>
    );
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.updateDocuments({ ...this.state });
  }

  render() {
    const {
      last_name,
      first_name,
      email,
      adresse,
      code_postal,
      ville,
      pays,
      telephone,
      responsable_id,
      raison_sociale,
      statut_juridique,
      type_association,
      numero_rna,
      objet_structure,
      parution_journal_officiel,
      statuts_signe,
      liste_signee_membre,
    } = this.state;

    return (
      <div className="informations-association-container">
        <p className="titre">
          Ces informations sont nécessaires pour la vérification de votre association.
          <br />
          Une fois que votre association est vérifié, vos collectes seront publiques
        </p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <h3 className="titre" style={{ fontWeight: 600, margin: '30px auto 10px' }}>
            Informations sur le responsable
          </h3>
          <div className="multiple-input">
            <div className="div-input">
              <label>Nom</label>
              <Input
                placeholder=""
                name="last_name"
                value={last_name}
                onChange={(e) => this.changeLastName(e.target.value)}
              />
            </div>
            <div className="div-input">
              <label>Prénom</label>
              <Input
                placeholder=""
                name="first_name"
                value={first_name}
                onChange={(e) => this.changeFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className="multiple-input">
            <div className="div-input">
              <label>Numéro de télephone</label>
              <Input
                placeholder=""
                name="telephone"
                value={telephone}
                onChange={(e) => this.changeTelephone(e.target.value)}
              />
            </div>
            <div className="div-input">
              <label>Email</label>
              <Input
                placeholder=""
                name="email"
                value={email}
                onChange={(e) => this.changeEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="div-input long">
            <label>Adresse</label>
            <Input
              placeholder=""
              name="adresse"
              value={adresse}
              onChange={(e) => this.changeAdresse(e.target.value)}
            />
          </div>
          <div className="multiple-input">
            <div className="div-input">
              <label>Code Postal</label>
              <Input
                placeholder=""
                name="code_postal"
                value={code_postal}
                onChange={(e) => this.changeCodePostal(e.target.value)}
              />
            </div>
            <div className="div-input">
              <label>Ville</label>
              <Input
                placeholder=""
                name="ville"
                value={ville}
                onChange={(e) => this.changeVille(e.target.value)}
              />
            </div>
          </div>
          <div className="div-input long">
            <label>Pays</label>
            <div className="ant-input" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FBFAFA', borderRadius: 5, height: 36 }}>
              <div id="country" className={`${pays.toLowerCase()}`} style={{ height: 16 }}>
              </div>
              {this.renderCountries(pays)}
            </div>
          </div>
          <h3 className="titre" style={{ fontWeight: 600, margin: '30px auto 10px' }}>
            Documents justificatifs du responsable
          </h3>
          <div className="div-input long">
            <label>Carte d'identité ou passport du responsable</label>
            <FileUploader
              changeFile={(responsable_id) => this.changeResponsableId(responsable_id)}
              file={responsable_id}
            />
          </div>
          <h3 className="titre" style={{ fontWeight: 600, margin: '30px auto 10px' }}>
            Informations sur la structure
          </h3>
          <div className="div-input long">
            <label>Raison Sociale</label>
            <Input
              placeholder=""
              name="raison_sociale"
              value={raison_sociale}
              onChange={(e) => this.changeRaisonSociale(e.target.value)}
            />
          </div>
          <div className="multiple-input">
            <div className="div-input">
              <label>Forme juridique</label>
              <div className="ant-input" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FBFAFA', borderRadius: 5, height: 36 }}>
                <Select
                  onChange={(event) => this.changeStatutJuridique(event)}
                  value={statut_juridique}
                  bordered={false}
                  style={{ width: '100%' }}
                >
                  {formeJuridique.map(x => {
                    return <Option key={x.key} value={x.value}>{x.value}</Option>;
                  })}
                </Select>
              </div>
            </div>
            <div className="div-input">
              <label>Type association</label>
              <div className="ant-input" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FBFAFA', borderRadius: 5, height: 36 }}>
                <Select
                  onChange={(event) => this.changeTypeAssociation(event)}
                  value={type_association}
                  bordered={false}
                  style={{ width: '100%' }}
                >
                  {typeAssociation.map(x => {
                    return <Option key={x.key} value={x.value}>{x.value}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </div>
          <div className="div-input long">
            <label>Numéro RNA</label>
            <Input
              placeholder=""
              name="numero_rna"
              value={numero_rna}
              onChange={(e) => this.changeNumeroRNA(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Objet de la structure</label>
            <Input
              placeholder=""
              name="objet_structure"
              value={objet_structure}
              onChange={(e) => this.changeObjetStructure(e.target.value)}
            />
          </div>
          <h3 className="titre" style={{ fontWeight: 600, margin: '30px auto 10px' }}>
            Documents justificatifs de la structure
          </h3>
          <div className="div-input long">
            <label>Copie de la parution au journal officiel</label>
            <FileUploader
              changeFile={(data) => this.changeParutionJournalOfficiel(data)}
              file={parution_journal_officiel}
            />
          </div>
          <div className="div-input long">
            <label>Statuts signés de l'association</label>
            <FileUploader
              changeFile={(data) => this.changeStatutsSigne(data)}
              file={statuts_signe}
            />
          </div>
          <div className="div-input long">
            <label>Liste signée des membres du bureau (moins de deux ans)</label>
            <FileUploader
              changeFile={(data) => this.changeListeSigneeMembre(data)}
              file={liste_signee_membre}
            />
          </div>
          <div className="submit">
            <button type="submit">
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    );
  }
}
