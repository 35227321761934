import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Upload, message } from 'antd';
import { LoadingOutlined, InboxOutlined } from '@ant-design/icons';

async function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function beforeUpload(file) {
  const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
  if (!isJpgOrPngOrPdf) {
    message.error('Vous pouvez seulement utiliser des fichiers JPG/PNG/PDF !');
  }
  const isLt6M = file.size / 1024 / 1024 < 6;
  if (!isLt6M) {
    message.error("La taille du fichier ne doit pas dépasser 6 Mo !");
  }

  return isJpgOrPngOrPdf && isLt6M;
}

export default class ImageUploader extends Component {
  static propTypes = {
    changeFile: PropTypes.func.isRequired,
  }

  state = {
    loading: false,
  };

  componentDidMount() {
    if (this.props.file) {
      this.setState({ imageUrl: this.props.file });
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.file === null && this.props.file !== null && typeof this.props.file === "string") {
      this.setState({ imageUrl: this.props.file });
    }
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        if (info.file.type === 'application/pdf') {
          this.setState({ loading: false, fileName: info.file.name });
        } else {
          const image = new Image();
          image.src = imageUrl;

          image.onload = (e) => {
            this.setState({ loading: false, imageUrl: imageUrl });
          }
        }
        this.props.changeFile(info.file.originFileObj);
      });
    }
  };

  decider = (imageUrl, fileName) => {
    const uploadButton = (
      <div>
        <p className="ant-upload-drag-icon" style={{ fontSize: 30, color: '#2b8bbc' }}>
          {this.state.loading ? <LoadingOutlined /> : <InboxOutlined />}
        </p>
        <p className="ant-upload-text">Cliquez ici ou faites glisser un fichier</p>
      </div>
    );

    if (imageUrl) {
      if (typeof imageUrl === "string") {
          if (imageUrl.includes('.pdf')) {
            return <embed src={imageUrl} style={{ width: '100%', height: 400 }} />;
          }
          return <img src={imageUrl} alt="" style={{ width: '100%' }} />;
      }
      return <img src={imageUrl} alt="" style={{ width: '100%' }} />;
    } else if (fileName) {
      return <p style={{ width: '100%' }}>{fileName}</p>
    } else {
      return uploadButton;
    }
  }

  render() {
    const { imageUrl, fileName } = this.state;

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {this.decider(imageUrl, fileName)}
      </Upload>
    );
  }
}
