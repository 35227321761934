import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tracker from '../elements/tracker';

import SignupAssociation from './signup_association';
import SignupInformations from './signup_informations';
import SignupRappelle from './signup_rappelle';

export default class Signup extends Component {
  static propTypes = {
    signup: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    name: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    activation: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    error: null,
    signup: () => {},
    name: null,
    activation: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      name: null,
      domaine: null,
      first_name: null,
    }
  }

  componentDidMount() {
    if(this.props.name !== null) {
      this.handleName(this.props.name);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== null && prevProps.name === null) {
      this.handleName(this.props.name);
    }

    if (prevProps.activation === false && this.props.activation === true) {
      this.increment();
    }
  }

  handleName = (name) => {
    this.setState({ name });
  }

  handleDomaine = (domaine) => {
    this.setState({ domaine });
  }

  handleLastStep = (data) => {
    this.setState({ first_name: data.first_name });

    const obj = {
      ...data,
      nom: this.state.name,
      domaine: this.state.domaine,
    }

    this.props.signup(obj);
  }

  increment = () => {
    const { step } = this.state;
    if (step < 3) {
      this.setState({ step: step + 1 });
    }
  }

  decrement = () => {
    const { step } = this.state;
    if (step > 1) {
      this.setState({ step: step - 1 });
    }
  }

  renderSteps = (step) => {
    const { name, domaine } = this.state;
    switch (step) {
      case 1:
        return [
          <h2 key={1} className="title">
            Inscrivez-vous pour pouvoir créer votre première collecte
          </h2>,
          <p key={2} className="subtitle">
            Promis, cela ne sera pas long !
          </p>,
          <SignupAssociation
            key={3}
            name={name}
            domaine={domaine}
            handleName={(name) => this.handleName(name)}
            handleDomaine={(domaine) => this.handleDomaine(domaine)}
            handleNextStep={() => this.increment()}
          />
        ];
      case 2:
        return [
          <h2 key={4} className="title">
            On serait ravi d'en apprendre un peu plus sur vous !
          </h2>,
          <p key={5} className="subtitle">
            Pas d'inquiétude, c'est juste pour mieux vous connaître <span role="img" aria-label="smiley">😃</span>
          </p>,
          <SignupInformations
            key={6}
            handleNextStep={(data) => this.handleLastStep(data)}
            email={null}
          />
        ];
      case 3:
        return [
          <h2 key={7} className="title">
            Pour continuer, merci de valider votre compte grâce à l'email de confirmation
          </h2>,
          <p key={8} className="subtitle">
            Plus qu’un pas pour créer votre première collecte
          </p>,
          <SignupRappelle key={9} />
        ];
      default:
        return <div>Default</div>;
    }
  }

  render() {
    const { error } = this.props;
    const { step } = this.state;

    const percent = [33, 66, 100];

    return (
      <div className="loginInputContainer">
        <div className="loginEtape">
          <Tracker color={"#2b8bbc"} percent={percent[step - 1]} height={8} radius={4} />
          <p>
            Étape <span className="bold">{step}</span> sur 3
          </p>
        </div>
        {this.renderSteps(step)}
        {error !== null &&
          <div className="errorDiv">
            <p>{error}</p>
          </div>
        }
      </div>
    );
  }
}
