import React, { Component } from "react";
import PropTypes from "prop-types";

import { Input, Select } from "antd";

import ImageUploader from "../elements/imageUploader";

const { TextArea } = Input;

const { Option } = Select;

export default class Association extends Component {
  static propTypes = {
    association: PropTypes.object.isRequired,
    updateAssociation: PropTypes.func.isRequired,
  };

  static defaultProps = {
    association: {},
    updateAssociation: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      nom: null,
      description: null,
      photo: null,
      logo: null,
      domaine_activite: null,
      adresse: null,
      code_postal: null,
      ville: null,
      pays: "FR",
      telephone: null,
      site_web: null,
      reconnaissance: "Mission d'intérêt général",
      defiscalisation_percent: 0,
      label_don_en_confiance: false,
      label_ideas: false,
    };
  }

  componentDidMount() {
    const { association } = this.props;

    if (association !== null) {
      const label_don_en_confiance =
        association.grand_label_associatif !== null
          ? association.grand_label_associatif.includes("Don en Confiance")
          : false;
      const label_ideas =
        association.grand_label_associatif !== null
          ? association.grand_label_associatif.includes("Ideas")
          : false;
      this.setState({
        nom: association.nom !== null ? association.nom : "",
        description:
          association.description !== null ? association.description : "",
        photo: association.photo !== null ? association.photo : null,
        domaine_activite:
          association.domaine_activite !== null
            ? association.domaine_activite
            : "",
        adresse: association.adresse !== null ? association.adresse : "",
        code_postal:
          association.code_postal !== null ? association.code_postal : "",
        ville: association.ville !== null ? association.ville : "",
        pays: association.pays !== null ? association.pays : "FR",
        telephone: association.telephone !== null ? association.telephone : "",
        site_web: association.site_web !== null ? association.site_web : "",
        logo: association.logo !== null ? association.logo : null,
        reconnaissance:
          association.reconnaissance !== null
            ? association.reconnaissance
            : "D'intérêt général",
        defiscalisation_percent:
          association.defiscalisation_percent !== null
            ? association.defiscalisation_percent
            : 0,
        label_don_en_confiance,
        label_ideas,
      });
    }
  }

  changeNom = (nom) => {
    this.setState({ nom });
  };

  changeDescription = (description) => {
    this.setState({ description });
  };

  changePhoto = (photo) => {
    this.setState({ photo });
  };

  changeLogo = (logo) => {
    this.setState({ logo });
  };

  changeAdresse = (adresse) => {
    this.setState({ adresse });
  };

  changeCodePostal = (code_postal) => {
    this.setState({ code_postal });
  };

  changeVille = (ville) => {
    this.setState({ ville });
  };

  changePays = (pays) => {
    this.setState({ pays });
  };

  changeDomaine = (domaine) => {
    this.setState({ domaine_activite: domaine });
  };

  changeReconnaissance = (reconnaissance) => {
    if (reconnaissance === "Aucune") {
      this.setState({ defiscalisation_percent: 0 });
    }
    this.setState({ reconnaissance });
  };

  changeDesfiscalisationPercent = (defiscalisation_percent) => {
    this.setState({ defiscalisation_percent });
  };

  changeLabelDonEnConfiance = (event) => {
    this.setState({ label_don_en_confiance: event.target.checked });
  };

  changeLabelIdeas = (event) => {
    this.setState({ label_ideas: event.target.checked });
  };

  changeTelephone = (telephone) => {
    this.setState({ telephone });
  };

  changeSiteWeb = (site_web) => {
    this.setState({ site_web });
  };

  renderCountries = (country) => {
    const countries = [
      {
        value: "UK",
        name: "Angleterre",
      },
      {
        value: "AT",
        name: "Autriche",
      },
      {
        value: "BE",
        name: "Belgique",
      },
      {
        value: "US",
        name: "États Unis",
      },
      {
        value: "CA",
        name: "Canada",
      },
      {
        value: "CN",
        name: "Chine",
      },
      {
        value: "FR",
        name: "France",
      },
      {
        value: "DE",
        name: "Allemagne",
      },
      {
        value: "IT",
        name: "Italie",
      },
      {
        value: "NL",
        name: "Hollande",
      },
      {
        value: "PT",
        name: "Portugal",
      },
      {
        value: "ES",
        name: "Espagne",
      },
      {
        value: "CH",
        name: "Suisse",
      },
    ];
    const france = [
      {
        value: "FR",
        name: "France",
      },
    ];
    return (
      <Select
        onChange={(event) => this.changePays(event)}
        value={country}
        bordered={false}
        style={{ width: "100%" }}
      >
        {france.map((x, index) => {
          return (
            <Option key={index} value={x.value}>
              {x.name}
            </Option>
          );
        })}
      </Select>
    );
  };

  renderDomaine = (domaine) => {
    const array = [
      "Action sociale",
      "Anciens élèves",
      "Arts et culture",
      "Club de loisirs et amicales",
      "Défense des droits",
      "Éducation et formation",
      "Environnement",
      "Étudiante",
      "Humanitaire - Caritative",
      "Médico-sociale",
      "Politique",
      "Préservation du patrimoine",
      "Professionnelle",
      "Protection des animaux",
      "Recherche et sciences",
      "Religion et spiritualité",
      "Sports",
      "Autre",
    ];

    return array.map((x) => {
      return (
        <Option key={x} value={x}>
          {x}
        </Option>
      );
    });
  };

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const {
      nom,
      description,
      photo,
      domaine_activite,
      adresse,
      code_postal,
      ville,
      pays,
      telephone,
      site_web,
      reconnaissance,
      defiscalisation_percent,
      label_don_en_confiance,
      label_ideas,
      logo,
    } = this.state;
    let grand_label_associatif = "";

    if (label_don_en_confiance) {
      grand_label_associatif += "Don en Confiance,";
    }

    if (label_ideas) {
      grand_label_associatif += "Ideas";
    }

    this.props.updateAssociation({
      nom,
      description,
      photo,
      domaine_activite,
      adresse,
      code_postal,
      ville,
      pays,
      telephone,
      site_web,
      reconnaissance,
      grand_label_associatif,
      defiscalisation_percent,
      logo,
      defiscalisable: reconnaissance !== null && reconnaissance !== "Aucune",
    });
  };

  render() {
    const {
      nom,
      description,
      photo,
      domaine_activite,
      adresse,
      code_postal,
      ville,
      pays,
      telephone,
      site_web,
      reconnaissance,
      defiscalisation_percent,
      label_don_en_confiance,
      label_ideas,
      logo,
    } = this.state;

    return (
      <div className="informations-association-container">
        <p className="titre">
          Visibles par tous les visiteurs sur Wisdana, ces informations sont
          nécessaires
          <br />
          pour la page de présentation de votre association.
        </p>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <div className="div-input long">
            <label>
              Nom de l'association <sup>*</sup>
            </label>
            <Input
              placeholder=""
              name="nom"
              value={nom}
              onChange={(e) => this.changeNom(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>
              Présentation de l'association <sup>*</sup>
            </label>
            <TextArea
              rows={4}
              placeholder=""
              name="description"
              value={description}
              onChange={(e) => this.changeDescription(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>
              Photo de profil <sup>*</sup>
            </label>
            <ImageUploader
              changePhoto={(photo) => this.changePhoto(photo)}
              image={photo}
            />
          </div>
          <div className="div-input long">
            <label>
              Domaine d'activité <sup>*</sup>
            </label>
            <div
              className="ant-input"
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FBFAFA",
                borderRadius: 5,
                height: 36,
              }}
            >
              <Select
                onChange={this.changeDomaine}
                bordered={false}
                style={{ width: "100%" }}
                value={domaine_activite}
              >
                {this.renderDomaine(domaine_activite)}
              </Select>
            </div>
          </div>
          <div className="div-input long">
            <label>
              Adresse <sup>*</sup>
            </label>
            <Input
              placeholder=""
              name="adresse"
              value={adresse}
              onChange={(e) => this.changeAdresse(e.target.value)}
            />
          </div>
          <div className="multiple-input">
            <div className="div-input">
              <label>
                Code Postal <sup>*</sup>
              </label>
              <Input
                placeholder=""
                name="code_postal"
                value={code_postal}
                onChange={(e) => this.changeCodePostal(e.target.value)}
              />
            </div>
            <div className="div-input">
              <label>
                Ville <sup>*</sup>
              </label>
              <Input
                placeholder=""
                name="ville"
                value={ville}
                onChange={(e) => this.changeVille(e.target.value)}
              />
            </div>
          </div>
          <div className="div-input long">
            <label>
              Pays <sup>*</sup>
            </label>
            <div
              className="ant-input"
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FBFAFA",
                borderRadius: 5,
                height: 36,
              }}
            >
              <div
                id="country"
                className={`${pays.toLowerCase()}`}
                style={{ height: 16 }}
              ></div>
              {this.renderCountries(pays)}
            </div>
          </div>
          <div className="div-input long">
            <label>Numéro de télephone</label>
            <Input
              placeholder=""
              name="telephone"
              value={telephone}
              onChange={(e) => this.changeTelephone(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Site web</label>
            <Input
              placeholder=""
              name="site_web"
              value={site_web}
              onChange={(e) => this.changeSiteWeb(e.target.value)}
            />
          </div>
          <div className="div-input long">
            <label>Logo de l'association</label>
            <ImageUploader
              changePhoto={(logo) => this.changeLogo(logo)}
              image={logo}
            />
          </div>
          <div className="div-input long">
            <label>Reconnaissance</label>
            <div
              className="ant-input"
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FBFAFA",
                borderRadius: 5,
                height: 36,
              }}
            >
              <Select
                onChange={this.changeReconnaissance}
                bordered={false}
                style={{ width: "100%" }}
                value={reconnaissance}
              >
                <Option key="aucune" value="Aucune">
                  Aucune
                </Option>
                <Option key="interet_general" value="Mission d'intérêt général">
                  Mission d'intérêt général
                </Option>
                <Option
                  key="utilite_publique"
                  value="Reconnue d'utilité publique"
                >
                  Reconnue d'utilité publique
                </Option>
              </Select>
            </div>
          </div>
          <div className="div-input long">
            <label>Pourcentage de défiscalisation</label>
            <div
              className="ant-input"
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FBFAFA",
                borderRadius: 5,
                height: 36,
              }}
            >
              <Select
                onChange={this.changeDesfiscalisationPercent}
                bordered={false}
                style={{ width: "100%" }}
                value={defiscalisation_percent}
              >
                <Option key="0" value={0}>
                  0%
                </Option>
                {reconnaissance !== "Aucune" && (
                  <Option key="66" value={66}>
                    66%
                  </Option>
                )}
                {reconnaissance !== "Aucune" && (
                  <Option key="75" value={75}>
                    75%
                  </Option>
                )}
              </Select>
            </div>
          </div>
          {/*<div className="multiple-input">
            <div className="div-input">
              <Checkbox
                checked={label_don_en_confiance}
                disabled={reconnaissance === 'Aucune' ? true:false}
                onChange={this.changeLabelDonEnConfiance}
              >
                <label>Label Don en Confiance</label>
              </Checkbox>
            </div>
            <div className="div-input">
              <Checkbox
                checked={label_ideas}
                disabled={reconnaissance === 'Aucune' ? true:false}
                onChange={this.changeLabelIdeas}
              >
                <label>Label IDEAS</label>
              </Checkbox>
            </div>
          </div>*/}
          <div className="submit">
            <button
              type="submit"
              disabled={
                !nom ||
                !description ||
                !adresse ||
                !code_postal ||
                !ville ||
                !pays
              }
            >
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    );
  }
}
