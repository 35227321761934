import React from "react";

import { useSelector } from "react-redux";

import { Redirect } from "react-router-dom";

const CheckAuth = () => {
  const auth = useSelector((state) => state.auth);
  const page = auth.authenticated ? "dashboard" : "login";

  if (!auth.authenticated) {
    localStorage.removeItem("token");
  }

  return <Redirect to={`/association/${page}`} />;
};

export default CheckAuth;
