import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class UpdateCard extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    updating: PropTypes.bool.isRequired,
  }

  truncate = (str, n) => {
    return (str.length > n) ? str.substr(0, n-1) + '...':str;
  };

  renderDate = (date) => {
    const new_date = new Date(date);
    const month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    return [
      <h1 key={0} style={{ color: '#fff', marginBottom: 0, fontWeight: 600 }}>{new_date.getDate()}</h1>,
      <p key={1} style={{ marginBottom: 0, fontWeight: 600 }}>{month[new_date.getMonth()]}</p>,
      <p key={2} style={{ marginBottom: 0 }}>{new_date.getFullYear()}</p>
    ];
  }

  render() {
    const { data, color, updating } = this.props;
    return (
      <div className="update-card-block" style={{ borderTopColor: color }}>
        <div className="left-block" style={{ background: color }}>
          <div className="update-card-date" style={{ background: color }}>
            {this.renderDate(data.created_at)}
          </div>
          <div className="arrows">
            <div className="arrow-right" style={{ borderLeftColor: color }}></div>
            <div className="arrow-left" style={{ borderRightColor: color }}></div>
          </div>
        </div>
        <div className="right-block">
          {!updating &&
            <p onClick={() => this.props.handleDelete(data.id)} style={{ color: color, float: 'right', cursor: 'pointer' }}>
              <i className="fal fa-2x fa-times"></i>
            </p>
          }
          {!updating &&
            <p onClick={() => this.props.handleUpdate(data.id)} style={{ color: color, float: 'right', cursor: 'pointer', marginRight: 10 }}>
              <i className="fad fa-2x fa-edit"></i>
            </p>
          }
          <h3 style={{ fontWeight: 600 }}>
            {this.truncate(data.titre, 30)}
          </h3>
          <p>
            {data.text}
          </p>
          {data.photo &&
            <img src={data.photo} alt={data.titre} />
          }
        </div>
      </div>
    );
  }
}
