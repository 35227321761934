import axios from 'axios';

import {
	GET_ASSOCIATION,
	PATCH_NEW_PASSWORD,
	ADD_NEW_ADMIN,
	GET_ADMINS,
	REMOVE_ADMIN,
	GET_DOCUMENTS,
	PATCH_DOCUMENTS,
	PATCH_ASSOCIATION,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const ASSOCIATION_URL = API_ROUTES.association;
const UPDATE_PASSWORD_URL = API_ROUTES.update_password;

export function getAssociation() {
  return function(dispatch) {
    axios.get(`${ASSOCIATION_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_ASSOCIATION, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function updateAssociation(id, data) {
	return function(dispatch) {
		const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    axios.patch(`${ASSOCIATION_URL}${id}/`, formData, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_ASSOCIATION, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function patchNewPassword(data) {
	return function (dispatch) {
		axios.patch(`${UPDATE_PASSWORD_URL}`, data, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			localStorage.removeItem('token');
			localStorage.setItem('token', response.data.token);
			dispatch({ type: PATCH_NEW_PASSWORD, success: true });
		})
		.catch(error => {
			console.log(error.response.data);
			dispatch({ type: PATCH_NEW_PASSWORD, success: false, error: error.response.data.error });
		});
	}
}

export function getAdmins(id) {
	return function (dispatch) {
		axios.get(`${ASSOCIATION_URL}${id}/admins/`, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			dispatch({ type: GET_ADMINS, payload: response.data });
		})
		.catch(error => {
			console.log(error.response);
		});
	}
}

export function addNewAdmin(id, data) {
	return function (dispatch) {
		axios.post(`${ASSOCIATION_URL}${id}/admins/`, data, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			dispatch({ type: ADD_NEW_ADMIN, payload: response.data });
		})
		.catch(error => {
			console.log(error.response);
		});
	}
}

export function removeAdmin(id, admin_id) {
	return function (dispatch) {
		axios.delete(`${ASSOCIATION_URL}${id}/admins/${admin_id}/`, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			dispatch({ type: REMOVE_ADMIN, payload: response.data });
		})
		.catch(error => {
			console.log(error.response);
		});
	}
}

export function getDocuments(id) {
	return function (dispatch) {
		axios.get(`${ASSOCIATION_URL}${id}/documents/`, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			dispatch({ type: GET_DOCUMENTS, payload: response.data });
		})
		.catch(error => {
			console.log(error.response);
		});
	}
}

export function updateDocuments(id, data) {
	return function (dispatch) {
		const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

		axios.patch(`${ASSOCIATION_URL}${id}/documents/`, formData, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			dispatch({ type: PATCH_DOCUMENTS, payload: response.data });
		})
		.catch(error => {
			console.log(error.response);
		});
	}
}

export function flushSuccess() {
	return function(dispatch) {
		dispatch({ type: PATCH_NEW_PASSWORD, success: null, error: null });
	}
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: PATCH_ASSOCIATION, payload: null });
		dispatch({ type: GET_DOCUMENTS, payload: null });
		dispatch({ type: GET_ADMINS, payload: null });
  }
}
