import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, Select } from 'antd';

const { Option } = Select;

export default class SignupAssociation extends Component {
  static propTypes = {
    name: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    domaine: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    handleNextStep: PropTypes.func.isRequired,
    handleName: PropTypes.func.isRequired,
    handleDomaine: PropTypes.func.isRequired,
  }

  static defaultProps = {
    name: null,
    domaine: null,
    handleNextStep: () => {},
    handleName: () => {},
    handleDomaine: () => {},
  }

  validate = () => {
    const { name, domaine } = this.props;
    if (name === null || domaine === null) {
      return false;
    }
    return true;
  }

  renderDomaine = (domaine) => {
    const array = [
      'Action sociale',
      'Anciens élèves',
      'Arts et culture',
      'Club de loisirs et amicales',
      'Défense des droits',
      'Éducation et formation',
      'Environnement',
      'Étudiante',
      'Humanitaire - Caritative',
      'Médico-sociale',
      'Politique',
      'Préservation du patrimoine',
      'Professionnelle',
      'Protection des animaux',
      'Recherche et sciences',
      'Religion et spiritualité',
      'Sports',
      'Autre'
    ];

    return array.map(x => {
      return (
        <Option key={x} value={x}>{x}</Option>
      );
    });
  }

  render() {
    const { name, domaine } = this.props;
    return (
      <div className="form">
        <div className="div-input">
          <label>Comment s'appelle votre association ? <sup>*</sup></label>
          <Input
            placeholder=""
            name="name"
            value={name}
            onChange={(e) => this.props.handleName(e.target.value)}
          />
        </div>
        <div className="div-input">
          <label>Quel est son domaine d'activité ? <sup>*</sup></label>
          <Select
            onChange={this.props.handleDomaine}
            placeholder="Choisir une catégorie"
          >
            {this.renderDomaine(domaine)}
          </Select>
        </div>
        <div className="div-input">
          <p style={{ color: 'rgba(0,0,0,0.65)' }}>* <i>obligatoire</i></p>
        </div>
        <div className="submit" onClick={this.validate() ? () => this.props.handleNextStep():() => {}}>
          <div className={this.validate() ? "button":"button disabled"}>
            Suivant <i className="fad fa-chevron-double-right"></i>
          </div>
        </div>
      </div>
    );
  }
}
