import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getPercent } from './percent_calculation';
import Image from './image';
import Tracker from './tracker';

import { FieldTimeOutlined, TrophyOutlined } from '@ant-design/icons';

export default class CollecteCard extends Component {
  static propTypes = {
    collecte: PropTypes.shape({
      association: PropTypes.shape({
        nom: PropTypes.string.isRequired,
        adresse: PropTypes.string,
        latitude: PropTypes.string.isRequired,
        longitude: PropTypes.string.isRequired,
        adresse_de_livraison: PropTypes.string,
        photo: PropTypes.string,
        description: PropTypes.string.isRequired,
        telephone: PropTypes.string.isRequired,
        nombre_de_membre: PropTypes.number,
        site_web: PropTypes.string,
        grand_label_associatif: PropTypes.string.isRequired,
        logo: PropTypes.string,
        defiscalisable: PropTypes.bool.isRequired,
        defiscalisation_percent: PropTypes.number.isRequired,
      }),
      date_debut: PropTypes.string.isRequired,
      date_fin: PropTypes.string,
      description: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      lieu_de_livraison: PropTypes.string.isRequired,
      nom: PropTypes.string.isRequired,
      percent: PropTypes.shape({
        price: PropTypes.number.isRequired,
        quantity_available: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
      }),
      photo: PropTypes.string,
      promue: PropTypes.bool.isRequired,
      sous_theme: PropTypes.number.isRequired,
      terminer: PropTypes.bool.isRequired,
      theme: PropTypes.shape({
        name: PropTypes.string.isRequired,
        couleur: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        image_selected: PropTypes.string.isRequired,
      })
    }),
    scrollPosition: PropTypes.object,
    deleteCollecte: PropTypes.func.isRequired,
  };

  static defaultProps = {
    collecte: {},
    scrollPosition: {},
    deleteCollecte: () => {},
  };

  dateFin = (date_fin) => {
    const now = new Date();
    const difference_in_time = new Date(date_fin).getTime() - now.getTime();
    const days = difference_in_time / (1000 * 3600 * 24);
    if (Math.round(days) > 0) {
        return `Plus que ${Math.ceil(days)} jours`;
    } else if (Math.round(days) === 0) {
      return "Aujourd'hui";
    }
    return "Terminée";

  }

  makeDecision = (collecte, colorBorder) => {
    if (collecte.terminer) {
      const produits = collecte.percent.total - collecte.percent.quantity_available;

      if (produits === 0 && collecte.percent.quantity_available !== 0) {
        return (
          <div className="update-card-date" style={{ background: colorBorder }}>
            <h1 className="titre"><TrophyOutlined /></h1>
            <p className="text">objectif atteint !</p>
          </div>
        );
      }
      return (
        <div className="update-card-date" style={{ background: colorBorder }}>
          <h1 className="titre"><FieldTimeOutlined /></h1>
          <p className="text">collecte terminée</p>
        </div>
      );
    } else {
      const produits = collecte.percent.quantity_available;
      return (
        <div className="update-card-date" style={{ background: colorBorder }}>
          <h1 className="titre">{produits}</h1>
          <p className="text">{produits > 1 ? "produits restants":"produit restant"}</p>
        </div>
      );
    }
  }

  truncate = (str, n) => {
    return (str.length > n) ? str.substr(0, n-1) + '...':str;
  };

  render() {
    const { collecte, scrollPosition } = this.props;
    const colorBorder = collecte.terminer ? "#E1E1E1":collecte.theme.couleur;
    const color = collecte.theme.couleur;
    const color2 = collecte.terminer ? "rgba(0,0,0,0.65)":collecte.theme.couleur;

    return (
      <div className="collecte" style={{ borderColor: colorBorder }}>
        <div className="collecte-image-stat">
          {this.makeDecision(collecte, colorBorder)}
          <div className="arrows">
            <div className="arrow-right" style={{ borderLeftColor: colorBorder }}></div>
            <div className="arrow-left" style={{ borderRightColor: colorBorder }}></div>
          </div>
        </div>
        <Image
          scrollPosition={scrollPosition}
          image={{
            alt: collecte.nom,
            height: 210,
            src: collecte.photo
          }}
        />
        <div className="collecte-body">
          <div className="text">
            <p className="titre">{this.truncate(collecte.nom, 27)}</p>
            <div className="collecte-subHeader">
              <span><FieldTimeOutlined />{collecte.date_fin === null ? "collecte permanente":this.dateFin(collecte.date_fin)}</span>
            </div>
            <p className="paragraph">{`${collecte.description.substring(0, 100)}...`}</p>
          </div>
          {collecte.validated &&
          <div className="stats">
            <div className="stat">
              <p className="chiffre" style={{ color: color2 }}>
                {collecte.percent.total - collecte.percent.quantity_available}
              </p>
              <p className="text">
                {(collecte.percent.total - collecte.percent.quantity_available) > 1 ? "produits offerts":"produit offert"}
              </p>
            </div>
            <div className="stat">
              <p className="chiffre" style={{ color: color2 }}>
                {Math.ceil(collecte.percent.price)}€
              </p>
              <p className="text">
                {Math.ceil(collecte.percent.price) > 1 ? "de dons récoltés":"de don récolté"}
              </p>
            </div>
          </div>
          }
        </div>
        <div className="collecte-last">
          <div>
          {collecte.percent.total !== 0 &&
            <Tracker color={color} percent={getPercent(collecte.percent)} height={16} radius={10} />
          }
          {collecte.percent.total !== 0 &&
            <p className="tracker-text">
              <span style={{ color: color, fontWeight: 600 }}>{getPercent(collecte.percent)}%</span> des produits déjà donnés
            </p>
          }
          </div>
          <a className="cta" href={!collecte.validated ? `/association/nouvelle-collecte/${collecte.id}`:`/association/collecte/${collecte.id}`}>
            {collecte.validated && "Voir la collecte"}
            {!collecte.validated && "Sélectionner les produits"}
          </a>
          {!collecte.validated &&
            <div className="cta alternate" onClick={() => this.props.deleteCollecte(collecte.id)}>
              Supprimer la collecte
            </div>
          }
        </div>
      </div>
    );
  }
}
