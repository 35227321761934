import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getCollectes, deleteCollecte, refresh } from "./actions";

import Stats from "../../components/collectes/stats";
import Collectes from "../../components/collectes/collectes";

// import custom css
import "./collectes.scss";
import "../../components/collectes/style.scss";

import env from "../../config/env";
const { APP_URL } = env;

class CollectesContainer extends Component {
  static propTypes = {
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    }),
    collectes: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    user: {},
    collectes: null,
  };

  componentDidMount() {
    this.props.getCollectes();
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  render() {
    const { user, collectes, association_id } = this.props;

    return (
      <div className="collectes-container">
        <div className="feed">
          <a
            href={`${APP_URL}/app/association/${association_id}`}
            style={{ color: "#fff" }}
          >
            Voir ma page publique
          </a>
        </div>
        <p className="title">
          Bonjour {user.first_name}, bienvenue sur Wisdana !
        </p>
        {collectes && <Stats collectes={collectes} />}
        {collectes && (
          <Collectes
            collectes={collectes}
            deleteCollecte={(data) => this.props.deleteCollecte(data)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    collectes: state.collectes.collectes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCollectes,
      deleteCollecte,
      refresh,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectesContainer);
