import React, { Component } from "react";
import PropTypes from "prop-types";

import { Input } from "antd";

import Error from "../../resources/img/error.svg";
import { Redirect } from "react-router";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
    };
  }
  static propTypes = {
    authUser: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    error: null,
  };

  changeEmail = (email) => {
    this.setState({ email });
  };

  changePassword = (password) => {
    this.setState({ password });
  };

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.authUser({
      username: this.state.email,
      password: this.state.password,
    });
  };

  render() {
    const { error } = this.props;
    // if (localStorage.getItem("token")) return <Redirect to="/association" />;
    return (
      <div className="loginInputContainer">
        <h2 className="title">Bienvenue dans l'espace association</h2>
        <p className="subtitle">Ravi de vous revoir !</p>
        <form className="form" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="div-input">
            <label>Adresse email</label>
            <Input
              placeholder=""
              name="email"
              value={this.state.email}
              onChange={(e) => this.changeEmail(e.target.value)}
            />
          </div>
          <div className="div-input">
            <label>Mot de passe</label>
            <Input.Password
              placeholder=""
              name="password"
              value={this.state.password}
              onChange={(e) => this.changePassword(e.target.value)}
            />
          </div>
          {error !== null && (
            <div className="errorDiv">
              <p>
                <img
                  src={Error}
                  alt="Erreur"
                  height="20"
                  width="20"
                  style={{ marginTop: -4, marginLeft: 10 }}
                />{" "}
                {error}
              </p>
            </div>
          )}
          <div className="submit">
            <button
              type="submit"
              disabled={!this.state.email || !this.state.password}
            >
              Se connecter
            </button>
          </div>
        </form>
      </div>
    );
  }
}
