import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal, Switch } from 'antd';
import { CloseOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default class SuiviTrackingDetails extends Component {
  static propTypes = {
    x: PropTypes.object.isRequired,
    y: PropTypes.object.isRequired,
    updateLivraison: PropTypes.func.isRequired,
    handleDetail: PropTypes.func.isRequired,
  }

  state = {
    checked: false,
  }

  getDate = (date) => {
    const new_date = new Date(date);
    const day = new_date.getDate() > 9 ? new_date.getDate(): `0${new_date.getDate()}`;
    const month = new_date.getMonth() > 8 ? new_date.getMonth() + 1:`0${new_date.getMonth() + 1}`;
    return `${day}/${month}/${new_date.getFullYear()}`;
  }

  onChange = (checked, livraison_id, id, e) => {
    e.preventDefault();
    const { updateLivraison } = this.props;
    if (checked === true) {
      const now = new Date();
      confirm({
        // title: "Êtes vous sur d'avoir reçue cette livraison ?",
        icon: <ExclamationCircleOutlined />,
        content: "Êtes vous sur d'avoir reçue cette livraison ?",
        onOk() {
          updateLivraison(livraison_id, { id, 'delivery_date': now.toISOString() });
        },
        cancelText: 'Annuler'
      });
    }
    this.setState({ checked: false });
  }

  render() {
    const { x, y } = this.props;
    const { checked } = this.state;

    return (
      <div className="collecte-suivi-livraison">
        <p className="collecte-suivi-livraison-text">Livraison #{x.id}<br />Colis #{y.numero_de_tracking}</p>
        <p className="collecte-suivi-livraison-text">{this.getDate(x.created_at)}</p>
        <p className="collecte-suivi-livraison-text">
          {y.tracking === null && <span style={{ color: '#EFEEEE', marginBottom: 0 }}>Suivre la livraison</span>}
          {y.tracking !== null && x.delivered_on_our_side === false && <a href={`${y.tracking}`} style={{ color: '#2b8bbc' }} target="_blank" rel="noopener noreferrer">Suivre la livraison</a>}
          {y.tracking !== null && x.delivered_on_our_side === true && <a href={`${y.tracking}`} style={{ color: '#2b8bbc' }} target="_blank" rel="noopener noreferrer">Commande livrée</a>}
        </p>
        <p
          className="collecte-suivi-livraison-text details"
          onClick={() => this.props.handleDetail(y.commandeItems)}
        >
          détails produits
        </p>
        <div className="collecte-suivi-livraison-text">{y.delivery_date === null ?
          <p>
            Confirmer la reception
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={checked}
              onChange={(check, e) => this.onChange(check, x.id, y.id, e)}
            />
          </p>
          :
          <span>Livraison reçue le {this.getDate(y.delivery_date)}</span>
        }</div>
      </div>
    )
  }
}
