import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { trackWindowScroll } from 'react-lazy-load-image-component';

import { Input } from 'antd';

import Image from '../elements/image';
// import FileUploader from '../elements/fileUploader';

const { TextArea } = Input;

const countries = [
  {
    value: "UK",
    name: "Angleterre",
  },
  {
    value: "AT",
    name: "Autriche",
  },
  {
    value: "BE",
    name: "Belgique",
  },
  {
    value: "US",
    name: "États Unis",
  },
  {
    value: "CA",
    name: "Canada",
  },
  {
    value: "CN",
    name: "Chine",
  },
  {
    value: "FR",
    name: "France",
  },
  {
    value: "DE",
    name: "Allemagne",
  },
  {
    value: "IT",
    name: "Italie",
  },
  {
    value: "NL",
    name: "Hollande",
  },
  {
    value: "PT",
    name: "Portugal",
  },
  {
    value: "ES",
    name: "Espagne",
  },
  {
    value: "CH",
    name: "Suisse",
  },
];

class DetailsClient extends Component {
  static propTypes = {
    order: PropTypes.object.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ])
  }

  static defaultProps = {
    order: {},
    scrollPosition: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      recu_fiscal: null,
      text: null,
      display: false,
    }
  }

  calculateTotalPrice = (data) => {
    const price = data.items.reduce((acc, val) => {
      acc += val.quantity * val.price;
      return acc;
    }, 0.00);
    return price.toFixed(2);
  }

  changeRecuFiscal = (recu_fiscal) => {
    this.setState({ recu_fiscal });
  }

  changeDisplay = () => {
    this.setState({ display: !this.state.display });
  }

  changeText = (text) => {
    this.setState({ text });
  }

  handleSubmit = (e, order_id) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.remercierUtilisateur({ ...this.state, order_id });
  }

  renderForm = (order) => {
    const { text } = this.state;
    const count = order.items.filter(x => x.livraison === null);
    if (count.length === 0 && order.thanked !== true) {
      return (
        <form className="form-suivi" onSubmit={(e) => this.handleSubmit(e, order.id)}>
          <div className="div-input long">
            <label>Remercier ce donateur</label>
            <TextArea
              rows={4}
              placeholder=""
              name="text"
              value={text}
              onChange={(e) => this.changeText(e.target.value)}
            />
          </div>
          <div className="submit">
            <button type="submit">
              Enregistrer
            </button>
          </div>
        </form>
      );
    } else if(count.length === 0 && order.thanked === true) {
      return (
        <p style={{ textAlign: 'center' }}>Vous avez déjà remercié ce donateur <span role="img" aria-label="emoji hands">🙌</span></p>
      )
    } else {
      return (
        <p style={{ textAlign: 'center' }}>Tous les produits n'ont pas encore été livrés, vous pourrez envoyer un message de remerciement lorsque ce sera le cas.</p>
      );
    }
  }

  // renderForm = () => {
  //   const { recu_fiscal } = this.state;
  //   return (
  //     <form className="form-suivi" onSubmit={(e) => this.handleSubmit(e)}>
  //       <div className="div-input long">
  //         <label>Ajoutez un reçu fiscal</label>
  //         <FileUploader
  //           changeFile={(recu_fiscal) => this.changeRecuFiscal(recu_fiscal)}
  //           file={recu_fiscal}
  //         />
  //       </div>
  //       <div className="submit">
  //         <button type="submit">
  //           Enregistrer
  //         </button>
  //       </div>
  //     </form>
  //   )
  // }

  renderDisplaySelect = (display) => {
    if (display) {
      return <div className="display-select" onClick={this.changeDisplay}>Cacher les produits <i className="fad fa-chevron-up"></i></div>;
    } else {
      return <div className="display-select" onClick={this.changeDisplay}>Voir les produits <i className="fad fa-chevron-down"></i></div>;
    }
  }

  renderProducts = (data, scrollPosition, display) => {
    if (display) {
      return data.items.map((product, index) => {
        return (
          <div key={`${product.id}-${index}`} className="item">
            <div className="image">
              <Image
                scrollPosition={scrollPosition}
                image={{
                  alt: product.article.nom,
                  height: 170,
                  src: product.article.photo
                }}
              />
            </div>
            <p className="titre">
              {product.article.nom}
            </p>
            <p className="quantite">
              Quantité commandée
            </p>
            <div className="price-block">
              <div className="number-input quantity-input">
                <p className="quantity">
                  {product.quantity}
                </p>
              </div>
              <p className="price">
                {product.price}€
              </p>
            </div>
          </div>
        );
      });
    }
  }


  render() {
    const { order, scrollPosition } = this.props;
    const { display } = this.state;

    return (
      <div>
        {this.renderDisplaySelect(display)}
        <div className="items-container suivi">
          {this.renderProducts(order, scrollPosition, display)}
        </div>
        {this.renderForm(order)}
        <div className="footer-suivi">
          <h2>Total de la commande: <span>{this.calculateTotalPrice(order)}€</span></h2>
        </div>
      </div>
    );
  }
}

export default trackWindowScroll(DetailsClient);
