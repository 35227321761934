import {
	GET_COLLECTES,
	DELETE_COLLECTE,
} from './types';

const initialState = {
  collectes: null,
}

function deleteCollecte(state, action) {
	const new_collectes = state.collectes.filter(x => x.id !== action.payload);

	return { ...state, collectes: new_collectes };
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_COLLECTES:
			return { ...state, collectes: action.payload };
		case DELETE_COLLECTE:
			return deleteCollecte(state, action);
		default:
			return { ...state };
	}

}
