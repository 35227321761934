import {
	GET_CATEGORIES,
	GET_PRODUITS,
	SUGGESTION,
	GET_ASSOCIATION,
} from './types';

const initialState = {
  produits: null,
  categories: null,
	suggestion: null,
	association: null,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_CATEGORIES:
      return { ...state, categories: action.payload };
		case GET_PRODUITS:
			return { ...state, produits: action.payload };
		case SUGGESTION:
			return { ...state, suggestion: action.payload };
		case GET_ASSOCIATION:
			return { ...state, association: action.payload };
		default:
			return { ...state };
	}
}
