import axios from 'axios';

import {
	GET_COLLECTES,
	DELETE_COLLECTE,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const COLLECTE_URL = API_ROUTES.collecte;

export function getCollectes() {
  return function(dispatch) {
    axios.get(`${COLLECTE_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_COLLECTES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response.data.error);
    });
  }
}

export function deleteCollecte(id) {
	return function(dispatch) {
		axios.delete(`${COLLECTE_URL}${id}/`, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			dispatch({ type: DELETE_COLLECTE, payload: id });
		})
		.catch(error => {
			console.log(error.response);
		});
	}
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_COLLECTES, payload: null });
  }
}
