import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter as Router } from "react-router-dom";
import reduxThunk from "redux-thunk";
import { IntlProvider } from "react-intl";
import axios from "axios";

import ScrollToTop from "./utils/scrolltotop";

import API_ROUTES from "./utils/api_routes";
import reducers from "./utils/reducers";
import routes from "./utils/routes";

import locale_fr from "./translations/fr.json";

import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga";

ReactGA.initialize("UA-179248838-1");

ReactGA.pageview(window.location.pathname + window.location.search);

// let mapStateToProps = (state) => { return { locale: state.i18n.locale, messages: state.i18n.messages } };
//
// let ConnectedIntlProvider = connect(mapStateToProps)(IntlProvider)

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(reducers);

const data = {
  fr: locale_fr,
};

// const language = navigator.language.split(/[-_]/)[0];

const language = "fr";

const App = () => {
  const [maintenance, setMaintenance] = useState(false);

  useEffect(() => {
    (async () => {
      axios
        .get(`${API_ROUTES.applications}maintenance/`)
        .then((res) => setMaintenance(res.data.maintenance))
        .catch((err) => console.error(err));
    })();
  }, []);

  return (
    <Provider store={store}>
      <IntlProvider locale={language} messages={data[language]}>
        <Router>
          <ScrollToTop>{routes(maintenance)}</ScrollToTop>
        </Router>
      </IntlProvider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.querySelector("#root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
