import React, { Component } from "react";

import { Input } from "antd";

import env from "../../config/env";
const { CONTACT_EMAIL } = env;
export default class SignupRappelle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: null,
    };
  }

  handleNumber = (number) => {
    this.setState({ number });
  };

  validate = () => {
    const { number } = this.state;

    if (
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(number)
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { number } = this.state;

    return (
      <div className="form">
        <div className="div-input" style={{ textAlign: "center" }}>
          <label>
            Si vous souhaitez être accompagné, indiquez votre numéro et nous
            vous rappellons !
          </label>
          <Input
            placeholder=""
            name="number"
            type="phone"
            value={number}
            onChange={(e) => this.handleNumber(e.target.value)}
          />
        </div>
        {this.validate() && (
          <a
            href={`mailto:${CONTACT_EMAIL}?subject=Rappelle%20pour%20finir%20inscription&body=Bonjour%2C%0D%0A%0D%0AJe%20souhaiterais%20me%20faire%20rappeler%20au%20${number}.%0D%0A%0D%0ABonne%20journ%C3%A9e%20%C3%A0%20vous%20!`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="submit">
              <div className="button">Me faire rappeler</div>
            </div>
          </a>
        )}
        {!this.validate() && (
          <div className="submit">
            <div className="button disabled">Me faire rappeler</div>
          </div>
        )}
      </div>
    );
  }
}
