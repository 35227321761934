import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import env from "../../config/env";
const { APP_URL, CONTACT_EMAIL, ROOT_DOMAIN } = env;

// import { HomeOutlined, ReadOutlined, UserOutlined } from "@ant-design/icons";

export default class Footer extends Component {
  static propTypes = {
    location: PropTypes.string.isRequired,
  };

  static defaultProps = {
    location: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      active: 0,
    };
  }

  componentDidMount() {
    this.renderActive(this.props.location);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location !== nextProps.location) {
      this.renderActive(nextProps.location);
    }
    return true;
  }

  handleActive = (active) => {
    this.setState({ active });
  };

  renderActive = (location) => {
    switch (location) {
      case "/app/feed":
        this.handleActive(0);
        break;
      case "/app/dashboard":
        this.handleActive(3);
        break;
      case "/app/comment-ca-marche":
        this.handleActive(1);
        break;
      default:
        this.handleActive(0);
        break;
    }
  };
  render() {
    // const { active } = this.state;

    return (
      <div className="footer-block">
        {/*<div className="footer-mobile">
          <div className={active === 0 ? "element active":"element"}>
            <Link to="/app/feed">
              <HomeOutlined style={{ fontSize: 20 }} />
            </Link>
          </div>
          <div className={active === 1 ? "element active":"element"}>
            <Link to="/app/comment-ca-marche">
              <ReadOutlined style={{ fontSize: 20 }} />
            </Link>
          </div>
          <div className={active === 3 ? "element active":"element"}>
            <Link to="/app/dashboard">
              <UserOutlined style={{ fontSize: 20 }} />
            </Link>
          </div>
        </div>*/}
        <div className="footer">
          <div className="footer-container">
            <div className="item">
              <div className="title">Aide & Informations</div>
              <a
                className="link"
                href={`https://${ROOT_DOMAIN}/comment-ca-marche`}
              >
                Comment ça marche
              </a>
              {
                <Link className="link" to="/association/nouvelle-collecte">
                  Créer une collecte
                </Link>
              }
              <Link className="link" to="/association/dashboard">
                Espace Association
              </Link>
              <a className="link" href={`${APP_URL}/app/feed`}>
                Espace Donateur
              </a>
              <a className="link" href={`https://${ROOT_DOMAIN}/faq`}>
                FAQ
              </a>
            </div>
            <div className="item">
              <div className="title">Un peu de lecture</div>
              <a
                className="link"
                href={`https://${ROOT_DOMAIN}/legal#confidentialite`}
              >
                Confidentialité & Cookies
              </a>
              <a
                className="link"
                href={`https://${ROOT_DOMAIN}/legal#paiement`}
              >
                Paiements sécurisés
              </a>
              <a
                className="link"
                href={`https://${ROOT_DOMAIN}/legal#condition`}
              >
                Conditions générales
              </a>
              {/*<a className="link" href="/rgpd" target="_blank">
                RGPD
              </a>*/}
            </div>
            <div className="item special">
              <div className="title">On se follow</div>
              <div className="special">
                <a
                  className="link"
                  href="https://www.facebook.com/wisdanaoff"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook"></i>
                </a>
                <a
                  className="link"
                  href="https://www.instagram.com/wisdana_off/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  className="link"
                  href={`mailto:${CONTACT_EMAIL}?subject=Question au sujet de Wisdana`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fal fa-paper-plane"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="secondary-footer">
          <p>Tous droits réservés © Wisdana 2020</p>
        </div>
      </div>
    );
  }
}
