import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { signupAssociation, refresh } from './actions';

import { parse } from 'query-string';

import logo from '../../resources/img/logo_white.svg';
import HeaderBG from '../../resources/img/header_bg.png';

import Signup from '../../components/auth/signup_informations';

// import custom css
import './auth.scss';
import '../../components/auth/style.scss';
import '../../components/elements/style.scss'

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoginRedirectUrl: '/association/dashboard',
      email: null,
      association: null,
    };
  }

  static propTypes = {
    authenticated: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    authenticated: null,
    error: null,
  };

  componentDidMount() {
    const query = parse(window.location.search);
    if(query.association && query.email) {
      this.setState({
        association: query.association,
        email: query.email,
      });
    }
  }

  signup = (data) => {
    const object = {
      ...data,
      association: parseInt(this.state.association),
    }
    this.props.signupAssociation(object);
  }

  render() {
    const { authenticated, error } = this.props;
    const { onLoginRedirectUrl, email } = this.state;

    if (authenticated) {
      return <Redirect push={true} to={onLoginRedirectUrl} />;
    }

    return (
      <div className="loginContainer" style={{ backgroundImage: `url(${HeaderBG})`}}>
        <div className="loginContainerBox">
          <img src={logo} className="logo" alt="logo" />
          <div className="loginSignupBox">
            <div className="loginInputContainer">
              <h2 className="title">
                On serait ravi d'en apprendre un peu plus sur vous !
              </h2>
              <p className="subtitle">
                Pas d'inquiétude, c'est juste pour mieux vous connaître <span role="img" aria-label="smiley">😃</span>
              </p>
              <Signup handleNextStep={(data) => this.signup(data)} error={error} email={email} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    error: state.auth.error,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signupAssociation,
    refresh,
  },dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
