import { combineReducers } from 'redux';
import AuthReducer from '../containers/auth/reducers';
import LayoutReducer from '../containers/layout/reducers';
import AuthActivationReducer from '../containers/auth_activation/reducers';
import CollectesReducer from '../containers/collectes/reducers';
import NouvelleCollecteReducer from '../containers/nouvelle_collecte/reducers';
import CollecteViewReducer from '../containers/collecte_view/reducers';
import InformationsReducer from '../containers/informations/reducers';
import ProduitsReducer from '../containers/produits/reducers';
import ResetPasswordReducer from '../containers/reset_password/reducers';

const rootReducer = combineReducers({
  auth: AuthReducer,
  layout: LayoutReducer,
  auth_activation: AuthActivationReducer,
  collectes: CollectesReducer,
  nouvelle_collecte: NouvelleCollecteReducer,
  collecte_view: CollecteViewReducer,
  informations: InformationsReducer,
  produits: ProduitsReducer,
  reset_password: ResetPasswordReducer,
});

export default rootReducer;
