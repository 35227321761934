import {
	AUTH_USER,
	UNAUTH_USER,
	AUTH_ERROR,
	AUTH_ACTIVATION,
	AUTH_VERIFICATION,
} from './types';

const initialState = {
  authenticated: null,
	error: null,
	activation: false,
}

export default function (state = initialState, action) {
	switch(action.type) {
		case AUTH_USER:
			return { ...state, authenticated: action.authenticated, activation: action.activation, error: null };
		case UNAUTH_USER:
			return { ...state, authenticated: false } ;
		case AUTH_ERROR:
			return { ...state, authenticated: false, error: action.error, activation: false };
		case AUTH_ACTIVATION:
			return {...state, authenticated: action.authed };
		case AUTH_VERIFICATION:
			return { ...state, authenticated: action.authed };
		default:
			return { ...state }
	}
}
