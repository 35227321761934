import {
	GET_COLLECTE,
	UPDATE_COLLECTE,
  GET_SUIVI,
	UPDATE_LIVRAISON,
  GET_ACTUALITES,
  POST_ACTUALITES,
	PATCH_ACTUALITE,
	DELETE_ACTUALITE,
	GET_CATEGORIES,
  GET_PRODUITS,
	GET_PRODUCTS_LIST,
	POST_PRODUITS,
  UPDATE_PRODUITS,
  DELETE_PRODUITS,
	POST_REMERCIEMENT,
} from './types';

const initialState = {
  collecte: null,
  produits: null,
  suivi: null,
  actualites: null,
	remerciement: null,
	produits_list: null,
	categories: null,
}

function postRemerciement(state, remerciement, order_id) {
	if (order_id === null) {
		return { ...state, remerciement };
	} else {
		const suivi = state.suivi;
		if (suivi === null) {
			return { ...state, remerciement };
		}
		const new_orders = suivi.orders.map(x => {
			if (parseInt(x.id) === parseInt(order_id)) {
				const new_obj = x;
				new_obj.thanked = true;
				return new_obj;
			}
			return x;
		});
		const new_suivi = {
			livraisons: suivi.livraisons,
			orders: new_orders,
		}
		return { ...state, remerciement, suivi: new_suivi };
	}
}

function postActualite(state, actualite) {
  const actualites = state.actualites;

  if (actualites === null) {
    return { ...state, actualites: [actualite]}
  }
  const new_actualites = [...actualites, actualite];
  return { ...state, actualites: new_actualites };
}

function patchActualite(state, actualite) {
	const actualites = state.actualites;

	if (actualites === null) {
		return { ...state, actualites: [actualite]}
	}

	const new_actualites = actualites.map(x => {
		if (x.id === actualite.id) {
			return actualite;
		}
		return x;
	});
	return { ...state, actualites: new_actualites };
}

function updateProduit(state, produit) {
  const produits = state.produits;

  if (produits === null) {
    return { ...state, produits: [produit]}
  }

  const new_produits = produits.map(x => {
    if (x.id === produit.id) {
      return produit;
    }
    return x;
  });
  return { ...state, produits: new_produits };
}

function addProduit(state, produit) {
	const produits = state.produits;

	if (produits === null) {
		return { ...state, produits: [produit] }
	}
	const new_produits = produits.concat(produit);
	return { ...state, produits: new_produits };
}

function updateLivraison(state, livraison) {
	const suivi = state.suivi;

  if (suivi === null) {
		const new_suivi = {
			livraisons: [livraison],
			orders: [],
		}
    return { ...state, suivi: new_suivi };
  }

  const new_livraisons = suivi.livraisons.map(x => {
    if (x.id === livraison.id) {
      return livraison;
    }
    return x;
  });
	const new_suivi = {
		livraisons: new_livraisons,
		orders: suivi.orders,
	}
  return { ...state, suivi: new_suivi };
}

function deleteProduit(state, produit) {
  const produits = state.produits;

  if (produits === null) {
    return { ...state };
  }

  const new_produits = produits.filter(x => x.id !== produit);
  return { ...state, produits: new_produits };
}

function deleteActualite(state, actualite_id) {
	const actualites = state.actualites;

	if (actualites === null) {
		return { ...state };
	}

	const new_actualites = actualites.filter(x => x.id !== actualite_id);
	return { ...state, actualites: new_actualites };
}

export default function (state = initialState, action) {
	switch(action.type) {
		case GET_COLLECTE:
			return { ...state, collecte: action.payload };
		case UPDATE_COLLECTE:
			return { ...state, collecte: action.payload };
    case GET_SUIVI:
      return { ...state, suivi: action.payload };
		case UPDATE_LIVRAISON:
			return updateLivraison(state, action.payload);
    case GET_ACTUALITES:
      return { ...state, actualites: action.payload };
    case POST_ACTUALITES:
      return postActualite(state, action.payload);
		case PATCH_ACTUALITE:
			return patchActualite(state, action.payload);
		case DELETE_ACTUALITE:
			return deleteActualite(state, action.payload);
    case GET_PRODUITS:
      return { ...state, produits: action.payload };
		case POST_PRODUITS:
			return addProduit(state, action.payload);
    case UPDATE_PRODUITS:
      return updateProduit(state, action.payload);
    case DELETE_PRODUITS:
      return deleteProduit(state, action.payload);
		case POST_REMERCIEMENT:
			return postRemerciement(state, action.payload, action.order_id)
		case GET_PRODUCTS_LIST:
			return { ...state, produits_list: action.payload };
		case GET_CATEGORIES:
			return { ...state, categories: action.payload };
		default:
			return { ...state };
	}

}
