import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Drawer, Modal, Popconfirm } from 'antd';

import { CloseOutlined } from '@ant-design/icons';

import Products from './products';
import Image from '../elements/image';
import Suggestion from './suggestion';

import { trackWindowScroll } from 'react-lazy-load-image-component';

class NouvelleCollecteStep3 extends Component {
  static propTypes = {
    products: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    handleNext: PropTypes.func.isRequired,
    categories: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    getProducts: PropTypes.func.isRequired,
    getProductsByName: PropTypes.func.isRequired,
    sendSuggestion: PropTypes.func.isRequired,
    suggestion: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.bool.isRequired,
    ]),
    scrollPosition: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    toggleSuggestion: PropTypes.func.isRequired,
  }

  static defaultProps = {
    products: null,
    handleNext: () => {},
    categories: null,
    getProducts: () => {},
    getProductsByName: () => {},
    scrollPosition: null,
    sendSuggestion: () => {},
    suggestion: null,
    toggleSuggestion: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      drawer: false,
      suggestion_drawer: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.suggestion === null && this.props.suggestion === true) {
      this.setState({ suggestion_drawer: false });
      this.props.toggleSuggestion();
    }
  }

  changeQuantity = (e, quantity, obj) => {
    e.preventDefault();
    this.handleUpdateItem(obj, quantity);
  }

  decrement = (e, quantity, obj) => {
    e.preventDefault();
    const new_quantity = obj.quantity - quantity
    this.handleUpdateItem(obj, new_quantity);
  }

  increment = (e, quantity, obj) => {
    e.preventDefault();
    const new_quantity = obj.quantity + quantity;
    this.handleUpdateItem(obj, new_quantity);
  }

  handleAddNewItem = (new_item) => {
    const items = [...this.state.items, new_item];
    this.setState({ items });
  }

  handleUpdateItem = (new_item, quantite) => {
    const items = this.state.items.reduce((acc, val) => {
      if (new_item.id === val.id) {
        if (parseInt(quantite) > 0) {
          new_item.quantity = parseInt(quantite);
          acc.push(new_item);
        } else if (quantite === "") {
          new_item.quantity = quantite;
          acc.push(new_item);
        }
      } else {
        acc.push(val);
      }
      return acc;
    }, []);
    this.setState({ items });
  }

  handleNext = () => {
    const check = this.state.items.reduce((acc, val) => {
      if (acc[val.categorie.nom]) {
        acc[val.categorie.nom].total += (val.quantity * val.prix);
      } else {
        acc[val.categorie.nom] = {}
        acc[val.categorie.nom].total = (val.quantity * val.prix);
        acc[val.categorie.nom].minimum = val.categorie.minimum;
      }
      return acc;
    }, {});

    const not_pass_categories = Object.keys(check).filter(x => check[x].total < check[x].minimum);

    if (not_pass_categories.length === 0) {
      const name = this.state.items.filter(x => x.categorie.nom === "Hygiène & Entretien");
      if (name.length > 0) {
        this.handleSpecialCase(this.props.handleNext, this.state.items);
      }
      this.props.handleNext({ items: this.state.items });
    } else {
      const items = not_pass_categories.map(x => {
        return { nom: x, total: check[x].total, min: check[x].minimum };
      });
      this.handleSpecialCaseCategories(items);
    }
  }

  handleSpecialCase = (handleNext, items) => {
    Modal.warning({
      title: 'Livraison des produits Alimentaire, Hygiène & Entretien',
      content: "Ces produits ne peuvent pas être livrés directement à votre association. Les commandes pourront être récupérées directement au Drive. Le magasin et le choix des horaires seront précisés sur votre récapitulatif de livraisons.",
      onOk() {
        handleNext({ items });
      }
    });
  }

  handleSpecialCaseCategories = (items) => {
    Modal.error({
      title: 'Minimum de commande pour une catégorie non atteint',
      content: this.specialCaseSentence(items)
    });
  }

  specialCaseSentence = (items) => {
    const mins = items.map(x => `${x.min}€`).join(', ');
    if (items.length > 1) {
      return `Le(s) produit(s) des catégories ${items.map(x => x.nom).join(', ')} n'ont pas atteint les minimum de livraison de ${mins}`
    }
    return `Le(s) produit(s) de la catégorie ${items[0].nom} n'a pas atteint le minimum de livraison de ${items[0].min}€`
  }

  renderProducts = (items, scrollPosition) => {
    return items.map(x => {
      return (
        <div key={x.id} className="item">
          <div className="croix" onClick={(e) => this.changeQuantity(e, 0, x)}>
            <CloseOutlined />
          </div>
          <div className="image">
            <Image
              scrollPosition={scrollPosition}
              image={{
                alt: x.nom,
                height: 170,
                src: x.photo
              }}
            />
          </div>
          <p className="titre">
            {x.nom}
          </p>
          <p className="quantite">
            Quantité souhaitée
          </p>
          <div className="price-block">
            <div className="number-input">
              <button onClick={(e) => this.decrement(e, 1, x)}></button>
              <input
                className="quantity"
                onChange={(e) => this.changeQuantity(e, e.target.value, x)}
                type="number"
                min={0}
                value={x.quantity}
                pattern="[0-9]*"
              />
              <button onClick={(e) => this.increment(e, 1, x)} className="plus"></button>
            </div>
            <p className="price">
              {x.prix}€ <span style={{ fontWeight: 200, fontSize: 10 }}>unit.</span>
            </p>
          </div>
        </div>
      );
    });
  }

  toggleDrawer = (bool) => {
    this.props.getProducts(0);
    this.setState({ drawer: bool, suggestion_drawer: false });
  }

  toggleDrawerSuggestion = (bool) => {
    this.setState({ drawer: false, suggestion_drawer: bool });
  }

  toggleDrawerAndAnnuler = () => {
    this.setState({ drawer: false, suggestion_drawer: false,  items: []});
  }

  render() {
    const { products, categories, scrollPosition } = this.props;
    const { items, drawer, suggestion_drawer } = this.state;

    return (
      <div className="nouvelle-collecte-step-products">
        <p className="titre">
          Sélection des produits dont vous avez besoin
        </p>
        <p className="titre">
          Vous ne trouvez pas le produit que vous cherchez ? Cliquez <span
            style={{ fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => this.toggleDrawerSuggestion(true)}
          >ici</span>
        </p>
        <div className="submit">
          {items.length === 0 &&
            <button disabled={true}>
              Suivant <i className="fad fa-chevron-double-right"></i>
            </button>
          }

          {items.length !== 0 ?
            (items.filter(x => x.quantity === 1).length === items.length ?
            <Popconfirm
              title="Toutes les quantités sont à 1. Êtes-vous sur de vouloir continuer ?"
              onConfirm={this.handleNext}
              okText="Oui"
              cancelText="Non"
            >
              <button>
                Suivant <i className="fad fa-chevron-double-right"></i>
              </button>
            </Popconfirm>:
            <button onClick={this.handleNext}>
              Suivant <i className="fad fa-chevron-double-right"></i>
            </button>):null
          }
        </div>
        <div className="items-block">
          <div key={0} className="item" style={{ alignSelf: 'center', border: 'none' }} onClick={() => this.toggleDrawer(true)}>
            <p className="new-product-icon fa-4x" onClick={() => this.toggleDrawer(true)}>
              <i className="fad fa-plus-circle"></i>
            </p>
            <p className="new-product-texte" style={{ cursor: 'pointer' }}>Ajouter un<br />nouveau produit</p>
          </div>
          {this.renderProducts(items, scrollPosition)}
        </div>
        <Drawer
          title="Sélectionner des produits"
          placement="right"
          closable={true}
          onClose={() => this.toggleDrawer(false)}
          visible={drawer}
          className="cart-drawer"
          headerStyle={{ textAlign: 'center' }}
          destroyOnClose={true}
          footerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}
          footer={
            (
              <div className="cta-validate-selection" onClick={() => this.toggleDrawer(false)} style={{ fontSize: 18, lineHeight: '18px' }}>
                Valider ma sélection
              </div>
            )
          }
        >
          <Products
            products={products}
            categories={categories}
            items={items}
            handleAddNewItem={(data) => this.handleAddNewItem(data)}
            handleRemoveItem={(data) => this.handleUpdateItem(data)}
            getProducts={(data) => this.props.getProducts(data)}
            getProductsByName={(data) => this.props.getProductsByName(data)}
            scrollPosition={scrollPosition}
          />
        </Drawer>
        <Drawer
          title="Suggestion de produit"
          placement="right"
          closable={true}
          onClose={() => this.toggleDrawerSuggestion(false)}
          visible={suggestion_drawer}
          className="cart-drawer"
          headerStyle={{ textAlign: 'center' }}
          destroyOnClose={true}
        >
          <Suggestion
            sendSuggestion={(data) => this.props.sendSuggestion(data)}
          />
        </Drawer>
      </div>
    );
  }
}

export default trackWindowScroll(NouvelleCollecteStep3);
