import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

export default class Administrateurs extends Component {
  static propTypes = {
    addNewAdmin: PropTypes.func.isRequired,
    admins: PropTypes.array.isRequired,
    removeAdmin: PropTypes.func.isRequired,
  }

  static defaultProps = {
    addNewAdmin: () => {},
    admins: [],
    removeAdmin: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      email: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.admins.length < this.props.admins.length) {
      this.setState({ email: null });
    }
    return true;
  }

  changeEmail = (email) => {
    this.setState({ email });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const { email } = this.state;
    this.props.addNewAdmin({ email });
  }

  validateEmail = () => {
    if(this.state.email === null) {
      return true;
    } else if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      return false;
    } else {
      return true;
    }
  }

  renderAdmins = (admins) => {
    return admins.map(x => {
      return (
        <div key={x.id} className="admins-email" onClick={() => this.props.removeAdmin(x.id)}>
          <p className="email">
            {x.user.email}
          </p>
          <i className="fal fa-times"></i>
        </div>
      );
    })
  }

  render() {
    const { email } = this.state;
    const { admins } = this.props;

    return (
      <div className="informations-association-container">
        <p className="titre">
          Paramétrez les droits d'accès à votre compte en invitant de nouveaux administrateurs pour gérer vos collectes
        </p>
        <div className="div-input long">
          <label>Administrateurs</label>
          {this.renderAdmins(admins)}
        </div>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div className="div-input long">
            <label>Ajouter un nouvel administrateur</label>
            <Input
              placeholder="Email du nouvel administrateur"
              name="email"
              value={email}
              onChange={(e) => this.changeEmail(e.target.value)}
            />
          </div>
          <div className="submit">
            <button type="submit" disabled={this.validateEmail()}>Ajouter</button>
          </div>
        </form>
      </div>
    );
  }
}
