import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Image from '../elements/image';

export default class Product extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    updateProduit: PropTypes.func,
    deleteProduit: PropTypes.func,
    terminer: PropTypes.bool.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    in_suivi: PropTypes.bool.isRequired,
    suivi: PropTypes.object.isRequired,
  }

  static defaultProps = {
    product: {},
    updateProduit: () => {},
    deleteProduit: () => {},
    terminer: false,
    scrollPosition: null,
    in_suivi: false,
    suivi: {},
  }


  render() {
    const { terminer, product, scrollPosition, in_suivi } = this.props;

    return (
      <div className="item">
        <div className="image">
          <Image
            scrollPosition={scrollPosition}
            image={{
              alt: product.article.nom,
              height: 170,
              src: product.article.photo
            }}
          />
        </div>
        <p className="titre">
          {product.article.nom}
        </p>
        <p className="quantite">
          {!in_suivi ? "Quantité souhaitée":"Quantité commandée"}
        </p>
        <div className="price-block">
          {terminer && <div className="number-input quantity-input"><p className="quantity">{product.quantity}</p></div>}
        </div>
      </div>
    );
  }
}
