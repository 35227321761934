import axios from 'axios';

import {
	GET_COLLECTE,
	UPDATE_COLLECTE,
  GET_SUIVI,
	UPDATE_LIVRAISON,
  GET_ACTUALITES,
  POST_ACTUALITES,
	PATCH_ACTUALITE,
	DELETE_ACTUALITE,
	GET_CATEGORIES,
  GET_PRODUITS,
	GET_PRODUCTS_LIST,
	POST_PRODUITS,
  UPDATE_PRODUITS,
  DELETE_PRODUITS,
	POST_REMERCIEMENT,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const COLLECTE_URL = API_ROUTES.collecte;
const EMAIL_URL = API_ROUTES.email;
const PRODUCTS_URL = API_ROUTES.products;
const CATEGORIES_URL = API_ROUTES.categories;

export function getCategories() {
  return function(dispatch) {
    axios.get(`${CATEGORIES_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_CATEGORIES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function getCollecte(id) {
  return function(dispatch) {
    axios.get(`${COLLECTE_URL}${id}/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_COLLECTE, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function updateCollecte(id, data) {
	return function(dispatch) {
		const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

		axios.patch(`${COLLECTE_URL}${id}/`, formData, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			dispatch({ type: UPDATE_COLLECTE, payload: response.data });
		})
		.catch(error => {
			console.log(error.response);
		});
	}
}

export function getSuivi(id) {
  return function(dispatch) {
    axios.get(`${COLLECTE_URL}${id}/suivi/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_SUIVI, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function updateLivraison(id, livraison_id, data) {
  return function(dispatch) {
    axios.patch(`${COLLECTE_URL}${id}/suivi/${livraison_id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: UPDATE_LIVRAISON, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function getActualites(id) {
  return function(dispatch) {
    axios.get(`${COLLECTE_URL}${id}/actualites/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_ACTUALITES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function postActualites(id, data) {

	const formData = new FormData();

	for (const key in data) {
		formData.append(key, data[key]);
	}

  return function(dispatch) {
    axios.post(`${COLLECTE_URL}${id}/actualites/`, formData, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: POST_ACTUALITES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function patchActualite(id, actualite, data) {
	const formData = new FormData();

	for (const key in data) {
		formData.append(key, data[key]);
	}

  return function(dispatch) {
    axios.patch(`${COLLECTE_URL}${id}/actualites/${actualite}/`, formData, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: PATCH_ACTUALITE, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function deleteActualite(id, actualite_id) {
	return function(dispatch) {
		axios.delete(`${COLLECTE_URL}${id}/actualites/${actualite_id}/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: DELETE_ACTUALITE, payload: actualite_id });
    })
    .catch(error => {
      console.log(error.response);
    });
	}
}

export function getProduits(id) {
  return function(dispatch) {
    axios.get(`${COLLECTE_URL}${id}/article/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUITS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function getProductsListByName(name) {
  return function (dispatch) {
    dispatch({ type: GET_PRODUCTS_LIST, payload: [] });
    axios.get(`${PRODUCTS_URL}?name=${name}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUCTS_LIST, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function getProductsList(category_id) {
  return function(dispatch) {
    dispatch({ type: GET_PRODUCTS_LIST, payload: [] });
    axios.get(`${CATEGORIES_URL}${category_id}/products/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUCTS_LIST, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function addProduit(id, data) {
	return function (dispatch) {
		axios.post(`${COLLECTE_URL}${id}/produits/`, data, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			dispatch({ type: POST_PRODUITS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
	}
}

export function updateProduit(id, produit_id, data) {
  return function(dispatch) {
    axios.patch(`${COLLECTE_URL}${id}/article/${produit_id}/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: UPDATE_PRODUITS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function deleteProduit(id, produit_id) {
  return function(dispatch) {
    axios.delete(`${COLLECTE_URL}${id}/article/${produit_id}/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: DELETE_PRODUITS, payload: produit_id });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function remercierUtilisateur(data) {
	return function(dispatch) {
		axios.post(`${EMAIL_URL}`, data, {
			headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
		})
		.then(response => {
			dispatch({ type: POST_REMERCIEMENT, payload: true, order_id: data.order_id });
		})
		.catch(error => {
			console.log(error.response);
		})
	}
}

export function refreshRemerciement() {
	return function(dispatch) {
		dispatch({ type: POST_REMERCIEMENT, payload: null, order_id: null });
	}
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_COLLECTE, payload: null });
    dispatch({ type: GET_PRODUITS, payload: null });
    dispatch({ type: GET_ACTUALITES, payload: null });
    dispatch({ type: GET_SUIVI, payload: null });
		dispatch({ type: GET_PRODUCTS_LIST, payload: null });
  }
}
