import axios from 'axios';

import {
  GET_PRODUITS,
  GET_CATEGORIES,
  SUGGESTION,
  GET_ASSOCIATION,
} from './types';

import API_ROUTES from '../../utils/api_routes';

const CATEGORIES_URL = API_ROUTES.categories;
const PRODUCTS_URL = API_ROUTES.products;
const ASSOCIATION_URL = API_ROUTES.association;
const ARTICLE_URL = API_ROUTES.article;

export function getAssociation() {
  return function(dispatch) {
    axios.get(`${ASSOCIATION_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_ASSOCIATION, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function getProductsByName(name) {
  return function (dispatch) {
    dispatch({ type: GET_PRODUITS, payload: null });
    axios.get(`${PRODUCTS_URL}?name=${name}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUITS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function getProduits(category_id) {
  return function(dispatch) {
    dispatch({ type: GET_PRODUITS, payload: null });
    axios.get(`${CATEGORIES_URL}${category_id}/products/`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_PRODUITS, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
      // console.log(error.response.data.error);
    })
  }
}

export function getCategories() {
  return function(dispatch) {
    axios.get(`${CATEGORIES_URL}`, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: GET_CATEGORIES, payload: response.data });
    })
    .catch(error => {
      console.log(error.response);
    });
  }
}

export function sendSuggestion(data, id) {
  return function(dispatch) {
    axios.post(`${ARTICLE_URL}suggestion/`, data, {
      headers: { Authorization: `JWT ${localStorage.getItem('token')}` }
    })
    .then(response => {
      dispatch({ type: SUGGESTION, payload: true });
    })
    .catch(error => {
      console.log(error.response.data.error);
    });
  }
}

export function toggleSuggestion() {
  return function (dispatch) {
    dispatch({ type: SUGGESTION, payload: null });
  }
}

export function refresh() {
  return function(dispatch) {
    dispatch({ type: GET_PRODUITS, payload: null });
    dispatch({ type: SUGGESTION, payload: null });
    dispatch({ type: GET_ASSOCIATION, payload: null });
  }
}
