import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, Modal } from 'antd';

import Image from '../elements/image';

const { Search } = Input;

export default class Products extends Component {
  static propTypes = {
    products: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    getProducts: PropTypes.func.isRequired,
    getProductsByName: PropTypes.func.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  }

  static defaultProps = {
    products: null,
    getProducts: () => {},
    getProductsByName: () => {},
    scrollPosition: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      category_selected: 0,
      sous_category_selected: 0,
    }
  }

  componentWillUnmount() {
    this.setState({
      category_selected: 0,
      sous_category_selected: 0,
    });
  }

  handleCategorySelected = (category_selected, categories) => {
    const name = categories.find(x => x.id === category_selected).nom;

    if (name === "Alimentaire" || name === "Hygiène & Entretien") {
      this.handleSpecialCase();
    }

    this.setState({ category_selected });
    this.props.getProducts(category_selected);
  }

  handleSpecialCase = () => {
    Modal.warning({
      title: 'Livraison des produits Alimentaire, Hygiène & Entretien',
      content: "Ces produits ne peuvent pas être livrés directement à votre association. Les commandes pourront être récupérées directement au Drive. Le magasin et le choix des horaires seront précisés sur votre récapitulatif de livraisons.",
    });
  }

  handleSousCategorySelected = (sous_category_selected) => {
    this.setState({ sous_category_selected });
  }

  renderCategory = (categories) => {
    const { category_selected } = this.state;

    return categories.filter(x => x.nom === "Animaux" || x.nom === "Hygiène & Entretien").map(x => {
      return (
        <div
          key={x.id}
          className={category_selected === x.id ? 'product-category active':'product-category'}
          onClick={() => this.handleCategorySelected(x.id, categories)}
        >
          <i className={`fad ${x.icon}`}></i> {x.nom}
        </div>
      );
    });
  }

  renderSousCategory = (categories) => {
    const { category_selected, sous_category_selected } = this.state;

    if (category_selected !== 0) {
      return categories.find(x => x.id === category_selected).sous_categorie.map(x => {
        return (
          <div
            key={x.id}
            className={sous_category_selected === x.id ? 'product-sous-category active':'product-sous-category'}
            onClick={() => this.handleSousCategorySelected(x.id)}
          >
            {x.nom}
          </div>
        );
      });
    }
    return null;
  }

  renderProducts = (products, sous_category_selected, scrollPosition) => {
    const products_to_render = sous_category_selected === 0 ? products:products.filter(x => x.sous_categorie.id === sous_category_selected);

    return products_to_render.map(x => {
      return (
        <div key={x.id} className="product-display alt">
          <div className="image">
            <Image
              scrollPosition={scrollPosition}
              image={{
                alt: x.nom,
                height: 170,
                src: x.photo
              }}
            />
          </div>
          <p className="titre">
            {x.nom}
          </p>
          <p className="price">
            {x.prix}€
          </p>
        </div>
      );
    });
  }

  handleProductByName = (value) => {
    if (value !== "") {
      this.props.getProductsByName(value);
    }
  }

  renderSearchForm = () => {
    return (
      <div className="search-container">
        <Search
          placeholder="Rechercher un produit"
          onSearch={value => this.handleProductByName(value)}
          className="search"
        />
      </div>
    );
  }

  render() {
    const { products, categories, scrollPosition } = this.props;
    const { sous_category_selected, category_selected } = this.state;

    return (
      <div className="products-block">
        {this.renderSearchForm()}
        {categories &&
          <div>
            <div className="products-category">
              {this.renderCategory(categories)}
            </div>
            <div className="products-sous-category">
              {category_selected !== 0 &&
                <div
                  key={0}
                  className={sous_category_selected === 0 ? 'product-sous-category active':'product-sous-category'}
                  onClick={() => this.handleSousCategorySelected(0)}
                >
                  Tous
                </div>
              }
              {this.renderSousCategory(categories)}
            </div>
          </div>
        }
        {category_selected !== 0 && products === null &&
          <div className="product-display-block">
            <div className="product-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
              <p className="price">
                X.XX€
              </p>
            </div>
            <div className="product-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
              <p className="price">
                X.XX€
              </p>
            </div>
            <div className="product-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
              <p className="price">
                X.XX€
              </p>
            </div>
            <div className="product-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
              <p className="price">
                X.XX€
              </p>
            </div>
            <div className="product-display ghost">
              <div className="image"></div>
              <p className="titre">XXX</p>
              <p className="price">
                X.XX€
              </p>
            </div>
          </div>
        }
        {products && products.length > 0 &&
          <div className="product-display-block">
            {this.renderProducts(products, sous_category_selected, scrollPosition)}
          </div>
        }
      </div>
    );
  }
}
