import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import Image from '../elements/image';

const { confirm, warning } = Modal;

export default class Product extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    updateProduit: PropTypes.func,
    deleteProduit: PropTypes.func,
    terminer: PropTypes.bool.isRequired,
    scrollPosition: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
    in_suivi: PropTypes.bool.isRequired,
    suivi: PropTypes.object.isRequired,
  }

  static defaultProps = {
    product: {},
    updateProduit: () => {},
    deleteProduit: () => {},
    terminer: false,
    scrollPosition: null,
    in_suivi: false,
    suivi: {},
  }

  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      quantity: 0,
      timeout: false,
      temp_quantity: 0,
    }
  }

  componentDidMount() {
    this.setState({ quantity: this.props.product.quantity });
  }

  changeQuantity = (e, quantity, obj) => {
    e.preventDefault();
    this.setState({ temp_quantity: quantity });
    if (this.state.timeout && this.timer !== null) {
      clearTimeout(this.timer);
    } else {
      this.setState({ timeout: true });
    }
    this.timer = setTimeout(() => this.handleUpdateItem(obj, quantity), 1000);
  }

  decrement = (e, quantity, obj) => {
    e.preventDefault();
    const new_quantity = obj.quantity - quantity
    this.handleUpdateItem(obj, new_quantity);
  }

  increment = (e, quantity, obj) => {
    e.preventDefault();
    const new_quantity = obj.quantity + quantity;
    this.handleUpdateItem(obj, new_quantity);
  }

  getQuantity = (product_id) => {
    const number = this.props.suivi.orders.reduce((acc, val) => {
      val.items.forEach(x => {
        if (x.article.id === product_id) {
          acc += x.quantity;
        }
      });
      return acc;
    }, 0);
    return number;
  }

  handleUpdateItem = (new_item, quantity) => {
    this.setState({ timeout: false });
    const { deleteProduit, updateProduit } = this.props;
    const quantity_bought = this.getQuantity(new_item.article.id);
    if (quantity !== "") {
      if (quantity === 0 && quantity_bought === 0) {
        confirm({
          title: 'Êtes vous sûr de vouloir enlever ce produit ?',
          icon: <ExclamationCircleOutlined />,
          content: 'Cette action est irréversible et le produit ne sera plus visible par nos donateurs',
          onOk() {
            deleteProduit(new_item.id);
          },
          onCancel() {
            return;
          },
        });
      } else if (quantity !== 0 && quantity_bought === 0) {
        this.setState({ quantity });
        new_item.quantity = quantity;
        updateProduit(new_item.id, new_item);
      } else {
        if (quantity < quantity_bought) {
          warning({
            title: "Trop d'articles supprimés",
            content: `Des donateurs ont déjà offert ce produit et vous ne pouvez plus baissez la quantité en dessous de ${quantity_bought}`,
          });
        } else {
          this.setState({ quantity });
          new_item.quantity = quantity;
          updateProduit(new_item.id, new_item);
        }
      }
    } else {
      this.setState({ quantity });
    }
  }

  render() {
    const { terminer, product, scrollPosition, in_suivi, color } = this.props;
    const { quantity, temp_quantity, timeout } = this.state;

    return (
      <div className="item">
        {!terminer &&
          <div className="croix" onClick={(e) => this.decrement(e, product.quantity, product)}>
            <CloseOutlined />
          </div>
        }
        <div className="image">
          <div className="image-products">
            <div className="update-card-date" style={{ background: color }}>
              <h1 className="titre">{this.getQuantity(product.article.id)}</h1>
              <p className="text">{this.getQuantity(product.article.id) > 1 ? "dons":"don"}</p>
            </div>
            <div className="arrows">
              <div className="arrow-right" style={{ borderLeftColor: color }}></div>
              <div className="arrow-left" style={{ borderRightColor: color }}></div>
            </div>
          </div>
          <Image
            scrollPosition={scrollPosition}
            image={{
              alt: product.article.nom,
              height: 170,
              src: product.article.photo
            }}
          />
        </div>
        <p className="titre">
          {product.article.nom}
        </p>
        <p className="quantite">
          {!in_suivi ? "Quantité souhaitée":"Quantité commandée"}
        </p>
        <div className="price-block">
          {!terminer &&
            <div className="number-input">
              <button onClick={(e) => this.decrement(e, 1, product)}></button>
              <input
                className="quantity"
                onChange={(e) => this.changeQuantity(e, e.target.value, product)}
                type="number"
                min={0}
                value={timeout ? temp_quantity:quantity}
              />
              <button onClick={(e) => this.increment(e, 1, product)} className="plus"></button>
            </div>
          }
          {terminer && <div className="number-input quantity-input"><p className="quantity">{product.quantity}</p></div>}
          <p className="price">
            {product.article.prix}€
          </p>
        </div>
      </div>
    );
  }
}
