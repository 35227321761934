import {
	AUTH_ERROR,
	AUTH_VERIFICATION,
	AUTH_ACTIVATION,
	FETCH_USER,
	GET_ASSOCIATION,
} from './types';

const initialState = {
  verify: null,
  user: null,
  error: null,
	registered: false,
}

function updateAssociation(state, association) {
	if (state.user !== null) {
		const new_user = state.user;
		new_user.association = association;
		return { ...state, user: new_user };
	}
	return { ...state };
}

export default function (state = initialState, action) {
	switch(action.type) {
		case AUTH_ERROR:
			return { ...state, verify: action.verify };
		case AUTH_VERIFICATION:
			return { ...state, verify: action.verify };
		case AUTH_ACTIVATION:
			return { ...state, user: action.user };
		case FETCH_USER:
			return { ...state, user: action.payload };
		case GET_ASSOCIATION:
			return updateAssociation(state, action.payload);
		default:
			return { ...state };
	}

}
