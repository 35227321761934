import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'antd';

export default class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
    }
  }

  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    status: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    resetPassword: () => {},
    error: null,
    status: null,
  };

  changeEmail = (email) => {
    this.setState({ email });
  }

  handleSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.resetPassword({ email: this.state.email });
  }

  render() {
    const { error, status } = this.props;

    return (
      <div className="loginInputContainer">
        {status === null &&
          <h2 className="title">
            Retrouver mon mot de passe
          </h2>
        }
        {status === null &&
          <p className="subtitle">
            Entrer votre adresse email ci-dessous et nous vous enverrons
            <br />un lien pour réinitialiser votre mot de passe.
          </p>
        }
        {status &&
          <p className="subtitle">
            Nous vous avons envoyé un email avec un lien pour modifier
            <br />votre mot de passe.
          </p>
        }
        {status === null &&
          <form className="form" onSubmit={e => this.handleSubmit(e)}>
            <div className="div-input">
              <label>Adresse email</label>
              <Input
                placeholder=""
                name="email"
                value={this.state.email}
                onChange={(e) => this.changeEmail(e.target.value)}
              />
            </div>
            {error !== null &&
              <div className="errorDiv">
                <p>{error}</p>
              </div>
            }
            <div className="submit">
              <button type="submit">Envoyer</button>
            </div>
          </form>
        }
      </div>
    );
  }
}
