import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { Link } from 'react-router-dom';

import { message } from 'antd';

import {
  getAssociation,
  updateAssociation,
  patchNewPassword,
  removeAdmin,
  addNewAdmin,
  getAdmins,
  getDocuments,
  updateDocuments,
  flushSuccess,
  refresh,
} from './actions';

// import custom css
import './informations.scss';
import '../../components/informations/style.scss';

import Menu from '../../components/elements/menu';

import Association from '../../components/informations/association';
import Administrateurs from '../../components/informations/administrateurs';
import MotDePasse from '../../components/informations/mot_de_passe';
import Documents from '../../components/informations/documents';
import Signature from '../../components/informations/signature';

class InformationsContainer extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    success: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    association: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    admins: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  }

  componentDidMount() {
    this.props.getAssociation();
    this.setState({ menu_selected: 1 });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.association !== this.props.association && prevProps.association !== null) {
      message.success({
        content: 'Vos changements ont bien été effectués',
        duration: 1,
      });
    }

    if (prevProps.documents !== this.props.documents && prevProps.documents !== null && this.props.documents !== null) {
      if (this.props.documents.responsable_id !== null && prevProps.documents.responsable_id !== null && !prevProps.documents.responsable_id.includes(this.props.documents.responsable_id)) {
        message.success({
          content: 'Fichier envoyé',
          duration: 1,
        });
      } else if (this.props.documents.parution_journal_officiel !== null && prevProps.documents.parution_journal_officiel !== null && !prevProps.documents.parution_journal_officiel.includes(this.props.documents.parution_journal_officiel)) {
        message.success({
          content: 'Fichier envoyé',
          duration: 1,
        });
      } else if (this.props.documents.statuts_signe !== null && prevProps.documents.statuts_signe !== null && !prevProps.documents.statuts_signe.includes(this.props.documents.statuts_signe)) {
        message.success({
          content: 'Fichier envoyé',
          duration: 1,
        });
      } else if (this.props.documents.liste_signee_membre !== null && prevProps.documents.liste_signee_membre !== null && !prevProps.documents.liste_signee_membre.includes(this.props.documents.liste_signee_membre)) {
        message.success({
          content: 'Fichier envoyé',
          duration: 1,
        });
      } else if (this.props.documents.signature !== null && prevProps.documents.signature !== null && !prevProps.documents.signature.includes(this.props.documents.signature)) {
        message.success({
          content: 'Fichier envoyé',
          duration: 1,
        });
      } else {
        message.success({
          content: 'Vos changements ont bien été effectués',
          duration: 1,
        });
      }
    }

    if (prevProps.association === null && this.props.association !== null) {
      this.props.getAdmins(this.props.association.id);
      this.props.getDocuments(this.props.association.id);
    }
    return true;
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  constructor(props) {
    super(props);
    this.state = {
      menu_selected: 1,
    }
  }

  changeMenuSelected = (menu_selected, user) => {
    this.setState({ menu_selected });
  }

  renderMenu = (menu_selected, user) => {
    const array = [{
      key: 1,
      value: 'Présentation',
    }, {
      key: 2,
      value: 'Documents'
    }, {
      key: 3,
      value: 'Administrateurs'
    }, {
      key: 4,
      value: 'Reçus fiscaux',
    }, {
      key: 5,
      value: 'Mot de passe'
    }];

    const array2 = [{
      key: 1,
      value: 'Présentation',
    }, {
      key: 5,
      value: 'Mot de passe'
    }];

    return <Menu data={user.super_admin ? array:array} active_menu={menu_selected} handleActiveMenu={(data) => this.changeMenuSelected(data, user)} />
  }

  renderPart = (menu_selected) => {
    const { association, admins, documents, user, error, success } = this.props;

    switch (menu_selected) {
      case 1:
        if (association === null) {
          return null;
        }
        return <Association association={association} updateAssociation={(data) => this.props.updateAssociation(association.id, data)} />;
      case 2:
        if (documents === null) {
          return null;
        }
        return <Documents documents={documents} updateDocuments={(data) => this.props.updateDocuments(association.id, data)}/>;
      case 3:
        if (association === null) {
          return null;
        }
        return <Administrateurs
          addNewAdmin={(data) => this.props.addNewAdmin(association.id, data)}
          admins={admins}
          removeAdmin={(id) => this.props.removeAdmin(association.id, id)}
          />;
      case 4:
        if(documents === null) {
          return null;
        }
        return <Signature documents={documents} updateDocuments={(data) => this.props.updateDocuments(association.id, data)}/>;
      case 5:
        if (user === null) {
          return null;
        }
        return <MotDePasse
          handleNewPassword={(data) => this.props.patchNewPassword(data)}
          error={error}
          flushSuccess={() => this.props.flushSuccess()}
          success={success}
          />
      default:
        return <p>Informations</p>;

    }
  }

  render() {
    const { menu_selected } = this.state;
    const { association, user } = this.props;

    if (association === null) {
      return null;
    }

    return (
      <div className="informations-view-container">
        <div className="feed">
          <Link to="/association/nouvelle-collecte" style={{ color: '#fff' }}>
            Créer une collecte
          </Link>
        </div>
        <div className="informations-verification-container">
          <h1 className="informations-verification-titre">
            Statut: {association.verified ? <span style={{ color: 'green' }}>Votre compte a été vérifié</span>:<span style={{ color: 'red' }}>Votre compte n'a pas encore été vérifié</span>}
          </h1>
          {!association.verified &&
            <p className="informations-verification-text">
              N’attendez pas que votre compte soit vérifié pour créer votre première collecte !
              <br />Elle sera accessible en privé grâce au lien de partage présent dans votre suivi de collecte.
              <br />Dès réception et validation des documents demandés, la collecte sera publique et mise en ligne parmi les autres du même thème.
            </p>
          }
        </div>
        {this.renderMenu(menu_selected, user)}
        {this.renderPart(menu_selected)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    association: state.informations.association,
    error: state.informations.error,
    success: state.informations.success,
    admins: state.informations.admins,
    documents: state.informations.documents,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAssociation,
    updateAssociation,
    patchNewPassword,
    addNewAdmin,
    removeAdmin,
    getAdmins,
    getDocuments,
    updateDocuments,
    flushSuccess,
    refresh,
  },dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(InformationsContainer);
