import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import jwt from "jsonwebtoken";

import Header from "../../components/layout/header";
import Subheader from "../../components/layout/subheader";
import Skeleton from "../../components/skeleton";

import CollectesContainer from "../collectes/collectesContainer";
import CollecteViewContainer from "../collecte_view/collecteViewContainer";
import NouvelleCollecteContainer from "../nouvelle_collecte/nouvelle_collecteContainer";
import InformationsContainer from "../informations/informationsContainer";
import ProduitsContainer from "../produits/produitsContainer";
import HelpContainer from "../help/helpContainer";

//Blog Like help pages
import RetourAide from "../../components/help/retour";
import CommentCaMarche from "../../components/help/commentCaMarche";
import CollecteReussi from "../../components/help/collecteReussi";
import TutoCreerUneCollecte from "../../components/help/tutoCreerUneCollecte";
import TutoRemercierDonateur from "../../components/help/tutoRemercierDonateur";

import {
  refreshToken,
  verifyToken,
  getUser,
  uploadLogo,
  refresh,
} from "./actions";

import "../../components/layout/style.scss";

class LayoutContainer extends Component {
  static propTypes = {
    verify: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    error: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    authenticated: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    user: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
  };

  static defaultProps = {
    verify: null,
    error: null,
    user: null,
    authenticated: null,
  };

  componentDidMount() {
    this.props.verifyToken();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.verify === null && this.props.verify === true) {
      if (
        localStorage.getItem("token") &&
        jwt.decode(localStorage.getItem("token"))
      ) {
        const expiry = new Date(
          jwt.decode(localStorage.getItem("token")).exp * 1000
        );
        const now = new Date();
        if (now.getDate() - expiry.getDate() === 1) {
          this.props.refreshToken();
        }
      }
    }
    return true;
  }

  componentWillUnmount() {
    this.props.refresh();
  }

  render() {
    const { verify, authenticated, user, location } = this.props;

    if (location.pathname === "/association") {
      return <Redirect push={false} to="/association/dashboard" />;
    }

    if (verify === false) {
      return (
        <Route
          render={({ staticContext }) => {
            // eslint-disable-next-line
            if (staticContext) staticContext.status = 403;
            return (
              <Redirect to={`/association/login?url=${location.pathname}`} />
            );
          }}
        />
      );
    }

    if (verify && authenticated && user === null) {
      this.props.getUser();
    }

    if (authenticated && user !== null) {
      return (
        <Header user={user.user} location={this.props.location.pathname}>
          <Subheader
            user={user}
            location={this.props.location}
            uploadLogo={(data) =>
              this.props.uploadLogo(data, user.association.id)
            }
          >
            <Route path="/association/dashboard" exact={true}>
              <CollectesContainer
                user={user.user}
                association_id={user.association.id}
              />
            </Route>
            <Route path="/association/nouvelle-collecte" exact={true}>
              <NouvelleCollecteContainer collecteId={null} />
            </Route>
            <Route
              path="/association/nouvelle-collecte/:id"
              render={({ match }) => (
                <>
                  <Redirect to={`/association/dashboard`} />
                  <NouvelleCollecteContainer
                    collecteId={parseInt(match.params.id)}
                  />
                </>
              )}
            />
            <Route
              path="/association/collecte/:id"
              render={({ match }) => (
                <CollecteViewContainer collecteId={parseInt(match.params.id)} />
              )}
            />
            <Route path="/association/informations/" exact={true}>
              <InformationsContainer user={user} />
            </Route>
            <Route path="/association/produits" exact={true}>
              <ProduitsContainer user={user} />
            </Route>
            <Route path="/association/help" exact={true}>
              <HelpContainer />
            </Route>
            <Route path="/association/help/info/comment-ca-marche" exact={true}>
              <RetourAide />
              <CommentCaMarche />
            </Route>
            <Route path="/association/help/astuce/collecte-reussi" exact={true}>
              <RetourAide />
              <CollecteReussi />
            </Route>
            <Route
              path="/association/help/tuto/remercier-donateur"
              exact={true}
            >
              <RetourAide />
              <TutoRemercierDonateur />
            </Route>
            <Route
              path="/association/help/tuto/creer-une-collecte"
              exact={true}
            >
              <RetourAide />
              <TutoCreerUneCollecte />
            </Route>
          </Subheader>
        </Header>
      );
    }

    return <Skeleton />;
  }
}

function mapStateToProps(state) {
  return {
    verify: state.layout.verify,
    error: state.layout.error,
    authenticated: state.auth.authenticated,
    user: state.layout.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      refreshToken,
      verifyToken,
      getUser,
      uploadLogo,
      refresh,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
